import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';
// Services
import { WINDOW_PROVIDERS } from "./services/windows.service";
import { LandingFixService } from '../shared/services/landing-fix.service';
import { InstagramService } from "./services/instagram.service";
import { ProductsService } from "./services/products.service";
import { WishlistService } from "./services/wishlist.service";
import { CartService } from "./services/cart.service";
import { OrderService } from "./services/order.service";
import { PaginationService } from "./classes/paginate";
// Pipes
import { OrderByPipe } from './pipes/order-by.pipe';
// components
import { HeaderComponent } from './header/header/header.component';
import { LeftSidebarComponent } from './header/left-sidebar/left-sidebar.component';
import { TopbarOneComponent } from './header/widgets/topbar/topbar-one/topbar-one.component';
import { TopbarTwoComponent } from './header/widgets/topbar/topbar-two/topbar-two.component';
import { NavbarComponent } from './header/widgets/navbar/navbar.component';
import { SettingsComponent } from './header/widgets/settings/settings.component';
import { SearchComponent } from './header/widgets/search/search.component';
import { LeftMenuComponent } from './header/widgets/left-menu/left-menu.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { FooterThreeComponent } from './footer/footer-three/footer-three.component';
import { InformationComponent } from './footer/widgets/information/information.component';
import { CategoriesComponent } from './footer/widgets/categories/categories.component';
import { WhyWeChooseComponent } from './footer/widgets/why-we-choose/why-we-choose.component';
import { CopyrightComponent } from './footer/widgets/copyright/copyright.component';
import { SocialComponent } from './footer/widgets/social/social.component';
import { AuthenticationService } from './services/authentication.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CampoNomePipe } from './pipes/campo-nome.pipe';
import { CalculateBRValuePipe } from './pipes/calculate-brvalue.pipe';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MainMenuComponent } from './header/main-menu/main-menu.component';
import { IconLinkComponent } from './header/widgets/icon-link/icon-link.component';
import { MainMenuItemComponent } from './header/widgets/main-menu-item/main-menu-item.component';
import { FooterComponent } from './footer/footer/footer.component';
import { InfoNavListComponent } from './footer/widgets/info-nav-list/info-nav-list.component';
import { HelpContactComponent } from './footer/widgets/help-contact/help-contact.component';
import { TermsComponent } from './footer/widgets/terms/terms.component';
import { DropdownComponent } from './header/widgets/dropdown/dropdown.component';
import { WhatsappButtonComponent } from './whatsapp-button/whatsapp-button.component';
import { InterceptorModule } from './services/interceptors/interceptor.module';


@NgModule({
  exports: [
    CommonModule,
    TranslateModule,
    HeaderComponent,
    LeftSidebarComponent,
    FooterOneComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    OrderByPipe,
    CampoNomePipe,
    CalculateBRValuePipe,
    LoadingSpinnerComponent,
    FooterComponent,
    WhatsappButtonComponent  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    InterceptorModule
  ],
  declarations: [
    HeaderComponent,
    LeftSidebarComponent,
    FooterOneComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    OrderByPipe,
    NavbarComponent,
    SettingsComponent,
    SearchComponent,
    LeftMenuComponent,
    TopbarOneComponent,
    TopbarTwoComponent,
    InformationComponent,
    CategoriesComponent,
    WhyWeChooseComponent,
    CopyrightComponent,
    SocialComponent,
    CampoNomePipe,
    CalculateBRValuePipe,
    LoadingSpinnerComponent,
    MainMenuComponent,
    IconLinkComponent,
    MainMenuItemComponent,
    FooterComponent,
    InfoNavListComponent,
    HelpContactComponent,
    TermsComponent,
    DropdownComponent,
    WhatsappButtonComponent

  ],
  providers: [
    WINDOW_PROVIDERS,
    LandingFixService,
    InstagramService,
    WishlistService,
    OrderService,
    PaginationService,
  ]
})
export class SharedModule { }
