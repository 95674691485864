<nav class="menu-nav">
  <ul>
    <app-main-menu-item
      link="https://loja.grupovessel.com/"
      label="Início"
    >
    </app-main-menu-item>
    <app-main-menu-item
      link="https://www.grupovessel.com/"
      label="Sobre a Vessel"
      target="_blank"
    >
    </app-main-menu-item>
    <app-main-menu-item
      link="https://www.grupovessel.com/fale-conosco/"
      label="Fale Conosco"
      target="_blank"
    >
    </app-main-menu-item>
  </ul>
</nav>