import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, combineLatest, Observable, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { CartItem } from "../classes/cart-item";
import { Product } from "../classes/product";
import { CurrencyService } from "../service/currency.service";

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("cartItem"));

@Injectable({
  providedIn: "root",
})
export class CartService {
  // Array
  public cartItems: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
  public cartItemsSubject: BehaviorSubject<CartItem[]> = new BehaviorSubject(
    []
  );
  public observer: Subscriber<{}>;

  constructor(
    private toastrService: ToastrService,
    private curencyService: CurrencyService
  ) {
    this.cartItems.next(products ? products : []);
    this.cartItemsSubject.next(products ? products : []);
  }

  // Get Products
  public getItems(): Observable<CartItem[]> {
    const itemsStream = new Observable((observer) => {
      if (!products) {
        // Try getting the products from the local storage again
        let storageProducts = JSON.parse(localStorage.getItem("cartItem"));
        products = !storageProducts ? [] : storageProducts;
      }
      observer.next(products);
      observer.complete();
    });
    return <Observable<CartItem[]>>itemsStream;
  }

  // Adicionar ao carrinho
  public addToCart(product: Product, quantity: number): CartItem | boolean {
    var item: CartItem | boolean = false;
    // If Products exist
    let hasItem = products.find((items, index) => {
      if (items.product.id == product.id && !product["camposCustomizados"]) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) {
          products[index]["quantity"] = qty;
          this.toastrService.success("Produto Adicionado.");
        }
        return true;
      } else if (items.product.id == product.id && product["hasSize"]) {
        let hasProduct = false;
        if (
          items.product["camposCustomizados"].tamanho ==
          product["camposCustomizados"].tamanho
        ) {
          hasProduct = true;
          let stock = this.calculateStockCounts2(products[index], quantity);
          if (stock) {
            if (items.product["camposCustomizados"].turma) {
              hasProduct = false;
            } else {
              let qty = products[index].quantity + quantity;
              products[index]["quantity"] = qty;
              this.toastrService.success("Produto Adicionado.");
            }
          }
        }
        return hasProduct;
      } else if (
        items.product.id == product.id &&
        product["camposCustomizados"]
      ) {
        let hasProduct = false;
        let stock = this.calculateStockCounts2(products[index], quantity);
        if (!stock) {
          hasProduct = true;
        }
        return hasProduct;
      }
    });
    // If Products does not exist (Add New Products)
    if (!hasItem) {
      item = { product: product, quantity: quantity };
      products.push(item);
      this.toastrService.success("Produto adicionado.");
    }

    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItemsSubject.next(products);
    return item;
  }

  // Update Cart Value
  public updateCartQuantity(
    product: Product,
    quantity: number
  ): CartItem | boolean {
    return products.find((items, index) => {
      if (items.product.id == product.id && !product["camposCustomizados"]) {
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts(products[index], quantity);
        if (qty != 0 && stock) products[index]["quantity"] = qty;
        localStorage.setItem("cartItem", JSON.stringify(products));
        this.cartItemsSubject.next(products);
        return true;
      }
      if (items.product == product && product["camposCustomizados"]) {
        let isProduct = false;
        isProduct = true;
        let qty = products[index].quantity + quantity;
        let stock = this.calculateStockCounts2(products[index], quantity);
        if (qty != 0 && stock) {
          products[index]["quantity"] = qty;
          localStorage.setItem("cartItem", JSON.stringify(products));
          this.cartItemsSubject.next(products);
        }
        return true;
      }
    });
  }

  public getCurrencies(): BehaviorSubject<any[]> {
    return this.curencyService.getCurrencies();
  }

  // Calculate Product stock Counts
  public calculateStockCounts(product: CartItem, quantity): CartItem | Boolean {
    let qty = product.quantity + quantity;
    // let stock = product.product.stock;
    let stock = product.product.quantidade;
    if (stock < qty) {
      /* this.toastrService.error(
        "You can not add more items than available. Em estoque " +
          stock +
          " items."
      ); */
      this.errorMsg(stock);
      return false;
    }
    return true;
  }

  public errorMsg(stock) {
    this.toastrService.error(
      "Você não pode adicionar mais itens do que o disponível. Em estoque " +
        stock +
        " items."
    );
  }

  public calculateStockCounts2(
    product: CartItem,
    quantity
  ): CartItem | Boolean {
    let resp = true;
    if (product.product["camposCustomizados"].turma) {
      let allProducts = products.filter((items) => {
        if (items.product["camposCustomizados"]) {
          if (
            product.product["camposCustomizados"].turma ==
            items.product["camposCustomizados"].turma
          ) {
            return items;
          }
        }
      });
      let qty = 0;
      allProducts.forEach((item) => {
        qty += item.quantity;
      });
      product.product["turmas"].find((items) => {
        if (items.nome == product.product["camposCustomizados"].turma) {
          qty += quantity;
          let stock = items.vagas - items.vagasOcupadas;
          if (stock < 0) stock = 0;
          console.log(stock);
          if (stock < qty) {
            this.errorMsg(stock);
            resp = false;
          }
        }
      });
    } else if (product.product["hasSize"]) {
      let allProducts = products.filter((items) => {
        if (items.product["camposCustomizados"]) {
          if (
            product.product["camposCustomizados"].tamanho ==
            items.product["camposCustomizados"].tamanho
          ) {
            return items;
          }
        }
      });
      let qty = 0;
      allProducts.forEach((item) => {
        qty += item.quantity;
      });
      product.product["estoques"].find((items) => {
        if (items.tamanho == product.product["camposCustomizados"].tamanho) {
          qty += quantity;
          let stock = items.quantidade;
          if (stock < qty) {
            this.errorMsg(stock);
            resp = false;
          }
        }
      });
    } else {
      let allProducts = products.filter(
        (items) => product.product.id == items.product.id
      );
      let qty = 0;
      allProducts.forEach((item) => {
        qty += item.quantity;
      });
      qty += quantity;
      let stock = parseInt(product.product.quantidade);
      if (stock < qty) {
        this.errorMsg(stock);
        resp = false;
      }
    }
    return resp;
  }

  // Removed in cart
  public removeFromCart(item: CartItem) {
    if (item === undefined) return false;
    const index = products.indexOf(item);
    products.splice(index, 1);
    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItemsSubject.next(products);
  }
  public clearCart() {
    products = [];
    localStorage.setItem("cartItem", JSON.stringify(products));
    this.cartItemsSubject.next(products);
  }
  // Total amount
  public getTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: CartItem[]) => {
        return products.reduce((prev, curr: CartItem) => {
          return prev + curr.product.price * curr.quantity;
        }, 0);
      })
    );
  }

  public getTotalEmRealPix() {
    return combineLatest([
      this.getItems(),
      this.curencyService.getCurrencies(),
    ]).pipe(
      map(([productss, currencies]) => {
        return productss.reduce((prev, curr: CartItem) => {
          let moeda = currencies.find(
            (moeda) => moeda["id"] == curr.product["moeda"]
          );
          if (moeda) {
            let prodPrice =
              curr.product.paymentValues && curr.product.paymentValues["pix"]
                ? curr.product.paymentValues["pix"]
                : curr.product.price;
            return prev + prodPrice * curr.quantity * moeda.ValorEmReal;
          } else {
          }
          return prev + curr.product.price * curr.quantity;
        }, 0);
      })
    );
  }

  public getTotalEmRealAmount(): Observable<number> {
    return combineLatest([
      this.getItems(),
      this.curencyService.getCurrencies(),
    ]).pipe(
      map(([productss, currencies]) => {
        return productss.reduce((prev, curr: CartItem) => {
          let moeda = currencies.find(
            (moeda) => moeda["id"] == curr.product["moeda"]
          );
          if (moeda) {
            return (
              prev + curr.product.price * curr.quantity * moeda.ValorEmReal
            );
          } else {
          }
          return prev + curr.product.price * curr.quantity;
        }, 0);
      })
    );
  }
  public getDescontoBoleto(): Observable<number> {
    return this.getItems().pipe(
      map((productss: CartItem[]) => {
        return productss.reduce((prev, curr: CartItem) => {
          if (curr.product["descontoBoletoType"] === 0) {
            return (
              prev + (curr.product["descontoBoleto"] / 100) * curr.product.price
            );
          } else {
            return prev + curr.product["descontoBoleto"];
          }
        }, 0);
      })
    );
  }
  getDescontoCupom(cuponsValidos) {
    return combineLatest([
      this.getItems(),
      this.curencyService.getCurrencies(),
    ]).pipe(
      map(([productss, currencies]) => {
        let total = productss.reduce((prev, curr: CartItem) => {
          let moeda = currencies.find(
            (moeda) => moeda["id"] == curr.product["moeda"]
          );
          if (moeda) {
            return (
              prev + curr.product.price * curr.quantity * moeda.ValorEmReal
            );
          } else {
          }
          return prev + curr.product.price * curr.quantity;
        }, 0);
        if (cuponsValidos) {
          if (cuponsValidos.discount_type == "true") {
            if (total - (cuponsValidos.discount_value / 100) * total < 0) {
              return total;
            } else {
              return (cuponsValidos.discount_value / 100) * total;
            }
          } else {
            if (total - cuponsValidos.discount_value < 0) {
              return total;
            } else {
              return cuponsValidos.discount_value;
            }
          }
        } else {
          return 0;
        }
      })
    );
  }
  public getTotalParcelaAmount(nParcelas, isBoleto: boolean): Observable<number> {
    return combineLatest([
      this.getItems(),
      this.curencyService.getCurrencies(),
    ]).pipe(
      map(([productss, currencies]) => {
        return productss.reduce((prev, curr: CartItem) => {
          let moeda = currencies.find(
            (moeda) => moeda["id"] == curr.product["moeda"]
          );
          let parcelas;
          if (isBoleto) {
            if (!curr.product["parcelasBoleto"]){
              return (
                prev + curr.product.price * moeda.ValorEmReal * curr.quantity
              );
            }
            parcelas = curr.product["parcelasBoleto"];
          } else {
            if (!curr.product["parcelas"]) {
              return (
                prev + curr.product.price * moeda.ValorEmReal * curr.quantity
              );
            }
            parcelas = curr.product["parcelas"];
          }
          if (moeda) {
            return (
              prev +
              curr.product.price *
                moeda.ValorEmReal *
                (1 + parcelas[nParcelas] / 100) *
                curr.quantity
            );
          }
          return (
            prev +
            curr.product.price *
              (1 + parcelas[nParcelas] / 100) *
              curr.quantity
          );
        }, 0);
      })
    );
  }
}
