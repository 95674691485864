import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper-loading-spinner',
  templateUrl: './wrapper-loading-spinner.component.html',
  styleUrls: ['./wrapper-loading-spinner.component.scss']
})
export class WrapperLoadingSpinnerComponent implements OnInit {
  @Input() isLoading: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
