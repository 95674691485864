<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="page-title">
                    <h2>produto</h2>
                </div>
            </div>
            <div class="col-sm-6">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a [routerLink]="''">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">produto</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section>
    <div class="collection-wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <ngx-slick-carousel class="product-slick" #slickModal="slick-carousel" [config]="slideConfig">
                        <div ngxSlickItem *ngFor="let image of product.photos">
                          <div>
                            <img [src]="image" alt="" class="img-fluid">
                          </div>
                        </div>
                    </ngx-slick-carousel>
                    <div class="row">
                        <div class="col-12 p-0">
                            <ngx-slick-carousel class="slider-nav" #slickModal="slick-carousel" 
                                     [config]="slideNavConfig">
                                <div ngxSlickItem *ngFor="let image of product.photos">
                                 <div>
                                   <img [src]="image" alt="" class="img-fluid">
                                 </div>
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="product-right product-description-box">
                        <h2>{{product.name}}</h2>
                        <div class="rating three-star  mb-2">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <div class="product-icon mb-3">
                            <ul class="product-social">
                                <li><a href=""><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://plus.google.com/discover"><i class="fa fa-google-plus"></i></a></li>
                                <li><a href="https://twitter.com/"><i class="fa fa-twitter"></i></a></li>
                            </ul>
                            <!-- <form class="d-inline-block" *ngIf="!productsService?.catalogMode">
                                <button class="wishlist-btn" (click)="addToWishlist(product)">
                                    <i class="fa fa-heart"></i>
                                    <span class="title-font">Add To WishList</span>
                                </button>
                            </form> -->
                        </div>
                        <div class="row product-accordion">
                            <div class="col-sm-12">
                                <div class="accordion theme-accordion" id="accordionExample">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    descrição do produto
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p>{{product.shortDetails}}</p>
                                                <div class="single-product-tables detail-section">
                                                    <table>
                                                        <tbody><tr>
                                                            <td>Febric:</td>
                                                            <td>Chiffon</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Color:</td>
                                                            <td>Yellow</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Material:</td>
                                                            <td>Crepe printed</td>
                                                        </tr>
                                                        <tr *ngIf="!productsService?.catalogMode">
                                                            <td>Avalibility:</td>
                                                            <td *ngIf="counter <= product.stock">Em estoque</td>
                                                            <td *ngIf="counter > product.stock">Sem estoque</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    details
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <div class="mt-2 text-center">
                                                    <iframe width="100%" height="300" src="https://www.youtube.com/embed/BUWzX78Ye_8" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    review
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                            <div class="card-body">
                                                <p>no reviews yet</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4" *ngIf="!productsService?.catalogMode">
                    <div class="product-right product-form-box">
                        <h4><del>{{product.salePrice | currency:productsService?.currency:'symbol'}}</del><span>{{product.discount}}% off</span></h4>
                        <h3>{{product.price | currency:productsService?.currency:'symbol'}}</h3>
                        <ul class="color-variant">
                          <li [class]="variant.color" (click)="slickModal.slickGoTo(i)" *ngFor="let variant of product.variants let i = index"></li>
                        </ul>
                        <div class="product-description border-product">
                            <h6 class="product-title">Time Reminder</h6>
                            <div class="timer">
                                <p id="timer">
                                <span>25
                                    <span class="padding-l">:</span>
                                    <span class="timer-cal">Days</span>
                                </span>
                                <span>22
                                    <span class="padding-l">:</span>
                                    <span class="timer-cal">Hrs</span>
                                </span>
                                <span>13
                                    <span class="padding-l">:</span>
                                    <span class="timer-cal">Min</span>
                                </span>
                                <span>57
                                    <span class="timer-cal">Sec</span>
                                </span>
                                </p>
                            </div>
                            <h6 class="product-title">Selecionar Tamanho</h6>
                            <div class="size-box">
                               <ul>
                                <li [ngClass]="{'active': selectedSize == size}" *ngFor="let size of product.size">
                                  <a [routerLink]="" (click)="changeSizeVariant(size)">{{size}}</a>
                                </li>
                              </ul>
                            </div>
                            <h6 class="product-title">quantidade</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                  <span class="input-group-prepend">
                                    <button type="button" (click)="decrement()" class="btn quantity-left-minus" data-type="minus" data-field="">
                                     <i class="ti-angle-left"></i>
                                    </button>
                                  </span>
                                  <input type="text" name="quantity" disabled class="form-control input-number" value="{{counter}}">
                                  <span class="input-group-prepend">
                                    <button type="button" (click)="increment()" class="btn quantity-right-plus" data-type="plus" data-field="">
                                    <i class="ti-angle-right"></i>
                                    </button>
                                   </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a [routerLink]="" (click)="addToCart(product, counter)" class="btn btn-solid" [class.disabled]="counter > product.stock">Adicionar ao carrinho</a>
                            <a [routerLink]="" (click)="buyNow(product, qty.value)" class="btn btn-solid" [class.disabled]="counter > product.stock">Comprar Agora</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Section ends -->
<!-- product-tab starts -->
<section class="tab-product m-0">
  <div class="container">
    <div class="row">
        <div class="col-sm-12 col-lg-12">
            <ul class="nav nav-tabs nav-material" id="top-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="top-home-tab" data-toggle="tab" href="#top-home" role="tab" aria-selected="true">Descrição</a>
                    <div class="material-border"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="profile-top-tab" data-toggle="tab" href="#top-profile" role="tab" aria-selected="false">Detalhes</a>
                    <div class="material-border"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="contact-top-tab" data-toggle="tab" href="#top-contact" role="tab" aria-selected="false">Video</a>
                    <div class="material-border"></div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="review-top-tab" data-toggle="tab" href="#top-review" role="tab" aria-selected="false">Write Review</a>
                    <div class="material-border"></div>
                </li>
            </ul>
            <div class="tab-content nav-material" id="top-tabContent">
                <div class="tab-pane fade show active" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
                    <p>
                        {{product.description}}
                    </p>
                </div>
                <div class="tab-pane fade" id="top-profile" role="tabpanel" aria-labelledby="profile-top-tab">
                    <p>
                       {{product.description}}
                    </p>
                    <div class="single-product-tables">
                        <table>
                            <tbody><tr>
                                <td>Febric</td>
                                <td>Chiffon</td>
                            </tr>
                            <tr>
                                <td>Color</td>
                                <td>Red</td>
                            </tr>
                            <tr>
                                <td>Material</td>
                                <td>Crepe printed</td>
                            </tr>
                            </tbody></table>
                        <table>
                            <tbody><tr>
                                <td>Length</td>
                                <td>50 Inches</td>
                            </tr>
                            <tr>
                                <td>Size</td>
                                <td>S, M, L .XXL</td>
                            </tr>
                            </tbody></table>
                    </div>
                </div>
                <div class="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                    <div class="mt-3 text-center">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="tab-pane fade" id="top-review" role="tabpanel" aria-labelledby="review-top-tab">

                    <form [formGroup]="form" class="theme-form">
                        <div class="form-row">
                            <div class="col-md-12 ">
                                <div class="media">
                                    <label>Rating</label>
                                    <div class="media-body ml-3">
                                        <div class="rating three-star">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="name">Name</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                            </div>
                            <div class="col-md-6">
                                <label for="email">Email</label>
                                <input type="text" class="form-control" id="email" placeholder="Email" required>
                            </div>
                            <div class="col-md-12">
                                <label for="review">Review Title</label>
                                <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                            </div>
                            <div class="col-md-12">
                                <label for="review">Review Title</label>
                                <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
<!-- product-tab ends -->
<app-related-products></app-related-products>
