<app-wrapper-loading-spinner
  [isLoading]="isLoading"
></app-wrapper-loading-spinner>

<div class="product-description-p">
  <!-- Breadcrumb -->
  <div class="breadcrumb-s">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
      <app-search-and-cart></app-search-and-cart>
    </div>
  </div>

  <!-- Main Section -->
  <section class="product-s">
    <div class="container">
      <div class="main-s">
        <!-- Left sidebar -->
        <div class="left-aside">
          <app-product-left-aside
            class="product-description-category-aside"
          ></app-product-left-aside>
          <app-new-products-aside
            class="product-description-news-aside"
          ></app-new-products-aside>
        </div>

        <!-- Product Main Content -->
        <main class="product-info-s">
          <!-- Top -->
          <div class="product-info-top">
            <!-- Left Image -->
            <div class="product-top-img-s" *ngIf="productData">
              <div class="img-highlight">
                <!-- <img [src]="productData.photos[0]" alt="" />
                <h2 class="title-w-style">Handebol<span>Espanha</span></h2>
                The product name is not being shown properly, removed for now
                <h2 class="title-w-style">{{ productData.name }}</h2> -->
                <ngx-slick-carousel
                  class="product-desc-carousel"
                  [config]="carouselConfig"
                >
                  <div
                    class="product-desc-carousel-slide"
                    *ngFor="let image of productData.photos"
                    ngxSlickItem
                  >
                    <img src="{{ image }}" alt="" />
                    <!-- <h2 class="title-w-style">{{ productData.name }}<span>{{ productData.highlight }}</span></h2> -->
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>

            <!-- Right Info -->
            <div class="product-main-info-s" *ngIf="productData">
              <!-- Product Name -->
              <div class="product-main-info-top">
                <h3 class="title-span">{{ productData.name }}</h3>
                <div class="product-price">
                  <p class="foreign-currency">
                    {{
                      productData.price | currency : productData["moedaCode"]
                    }}
                  </p>
                  <p
                    class="real-currency"
                    *ngIf="productData['moedaCode'] != 'BRL'"
                  >
                    {{
                      productData.price
                        | calculateBRValue : productData["moeda"]
                        | async
                        | currency : "BRL"
                    }}
                  </p>
                </div>
              </div>

              <!-- Form -->
              <div class="client-form">
                <h4 class="title">Preencha os dados</h4>
                <form
                  class="form"
                  action=""
                  [formGroup]="genericForm"
                  *ngIf="productData"
                >
                  <!-- Turma -->
                  <div class="input-wrapper" *ngIf="showTurmas">
                    <label>{{ turmaNome }}</label>
                    <select
                      name=""
                      class="form-select"
                      [formControlName]="'turma'"
                      [required]="true"
                    >
                      <option
                        *ngFor="let valor of productData.turmas"
                        [value]="valor.nome"
                      >
                        {{ valor.nome }}
                      </option>
                    </select>
                  </div>
                  <div
                    *ngFor="let campo of productData.campos"
                    class="input-wrapper"
                  >
                    <!-- Normal field -->
                    <input
                      *ngIf="
                        campo.tipo != 'Arquivo' &&
                        campo.tipo != 'Telefone' &&
                        campo.tipo != 'Data' &&
                        campo.tipo != 'CPF' &&
                        campo.tipo != 'Multi'
                      "
                      type="text"
                      name=""
                      id=""
                      [formControlName]="campo.nome"
                      [required]="campo.required"
                      [placeholder]="campo.nome"
                    />
                    <!-- Data Field -->
                    <input
                      *ngIf="campo.tipo == 'Data'"
                      type="text"
                      name=""
                      id=""
                      [imask]="{ mask: '00/00/0000' }"
                      [unmask]="false"
                      [formControlName]="campo.nome"
                      [required]="campo.required"
                      [placeholder]="campo.nome"
                    />
                    <!-- CPF Field -->
                    <input
                      *ngIf="campo.tipo == 'CPF'"
                      name=""
                      id=""
                      [imask]="{ mask: '000.000.000-00' }"
                      [unmask]="false"
                      [formControlName]="campo.nome"
                      [required]="campo.required"
                      [placeholder]="campo.nome"
                    />
                    <!-- Phone Field -->
                    <input
                      *ngIf="campo.tipo == 'Telefone'"
                      name=""
                      id=""
                      [imask]="{ mask: '(00) 00000-0000' }"
                      [unmask]="false"
                      [formControlName]="campo.nome"
                      [required]="campo.required"
                      [placeholder]="campo.nome"
                    />
                    <!-- Multi Field -->
                    <label *ngIf="campo.tipo == 'Multi'">{{
                      campo.nome
                    }}</label>
                    <select
                      name=""
                      class="form-select"
                      *ngIf="campo.tipo == 'Multi'"
                      [formControlName]="campo.nome"
                      [required]="campo.required"
                    >
                      <option
                        *ngFor="let valor of campo.multiValores"
                        [value]="valor"
                      >
                        {{ valor }}
                      </option>
                    </select>
                  </div>

                  <!-- Samples -->
                  <!-- <div class="input-wrapper">
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="Nome completo do(a) atleta"
                    />
                  </div>
                  <div class="input-wrapper">
                    <input type="text" placeholder="Escola" />
                  </div>
                  <div class="input-wrapper">
                    <input type="text" placeholder="RG" />
                  </div>
                  <div class="input-wrapper">
                    <input type="text" placeholder="CPF" />
                  </div>
  
                  <div class="input-wrapper">
                    <select name="" class="form-select">
                      <option value="">Selecione</option>
                      <option value="">Opção 1</option>
                      <option value="">Opção 2</option>
                      <option value="">Opção 3</option>
                      <option value="">Opção 4</option>
                    </select>
                  </div>
  
                  <div class="input-wrapper">
                    <input
                      type="date"
                      name=""
                      id=""
                      placeholder="Data de Nascimento"
                    />
                  </div> -->
                </form>
              </div>

              <!-- Buttons -->
              <div class="product-reference" *ngIf="productData">
                <div class="purchase-buttons">
                  <button
                    class="btn btn-secondary"
                    (click)="AddToCart(productData, 1)"
                  >
                    <a>Adicionar ao carrinho</a>
                  </button>
                  <button
                    class="btn btn-secondary"
                    (click)="BuyNow(productData, 1)"
                  >
                    <a>Comprar agora</a>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Bottom -->
          <section class="product-more-info-s">
            <ul class="tabs">
              <li
                class="tab active"
                (click)="setActiveTab(1)"
                [class.active]="isActive(1)"
              >
                <a>Descrição</a>
              </li>
              <li
                class="tab"
                (click)="setActiveTab(2)"
                [class.active]="isActive(2)"
              >
                <a>Detalhes</a>
              </li>
            </ul>
            <div class="product-box-detailing">
              <div
                class="product-box-content description"
                [class.active]="isActive(1)"
              >
                <p
                  *ngIf="productData && productData.description"
                  [innerHTML]="productData.description"
                ></p>
              </div>

              <div
                class="product-box-content details"
                [class.active]="isActive(2)"
              >
                <p
                  *ngIf="productData && productData.details"
                  [innerHTML]="productData.details"
                ></p>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </section>

  <!-- Social Media -->
  <app-social-media-section></app-social-media-section>
</div>
