<app-wrapper-loading-spinner
  [isLoading]="isLoading"
></app-wrapper-loading-spinner>

<div class="checkout-p">
  <!-- Breadcrumb -->
  <div class="breadcrumb-s">
    <div class="container">
      <div class="breadcrumb">
        <ul>
          <li><a routerLink="/">Início</a></li>
          <li>/</li>
          <li><a href="">Checkout</a></li>
        </ul>
      </div>
      <app-search-and-cart></app-search-and-cart>
    </div>
  </div>

  <!-- Main Section -->
  <main class="checkout-s">
    <div class="container">
      <!-- Forms -->
      <section class="checkout-form">
        <!-- Payment Form -->
        <div class="payment-form-s">
          <h4 class="form-title">Detalhes de pagamento</h4>
          <form class="form form-grid grid-payment" [formGroup]="form">
            <!-- Nome -->
            <div class="input-wrapper input-nome">
              <label for="firstname" class="form-label">*Nome</label>
              <input
                type="text"
                name="firstname"
                [formControl]="checkoutForm.controls['firstname']"
                value=""
                placeholder=""
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.firstname.touched &&
                  checkoutForm.controls.firstname.errors?.required
                "
              >
                <span class="warning-span">Nome é obrigatório.</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.firstname.touched &&
                  checkoutForm.controls.firstname.errors?.pattern
                "
              >
                <span class="warning-span"
                  >Nome deve somente conter letras.</span
                >
              </div>
            </div>

            <!-- Sobrenome -->
            <div class="input-wrapper input-sobrenome">
              <label for="lastname" class="form-label">*Sobrenome</label>
              <input
                type="text"
                name="lastname"
                [formControl]="checkoutForm.controls['lastname']"
                value=""
                placeholder=""
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.lastname.touched &&
                  checkoutForm.controls.lastname.errors?.required
                "
              >
                <span class="warning-span">Sobrenome é obrigatório.</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.lastname.touched &&
                  checkoutForm.controls.lastname.errors?.pattern
                "
              >
                <span class="warning-span"
                  >Sobrenome deve somente conter letras.</span
                >
              </div>
            </div>

            <!-- CPF -->
            <div class="input-wrapper input-cpf">
              <label for="cpf" class="form-label">*CPF</label>
              <input
                type="text"
                [imask]="{ mask: '000.000.000-00' }"
                [unmask]="true"
                [formControl]="checkoutForm.controls['document']"
                value=""
                placeholder=""
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.lastname.touched &&
                  checkoutForm.controls.lastname.errors?.required
                "
              >
                <span class="warning-span">CPF é obrigatório.</span>
              </div>
            </div>

            <!-- Telefone -->
            <div class="input-wrapper input-tel">
              <label for="phone" class="form-label">*Telefone</label>
              <input
                id="input_tel"
                [imask]="telMask"
                [unmask]="true"
                type="text"
                name="phone"
                [formControl]="checkoutForm.controls['phone']"
                autocomplete="off"
                (keydown)="onTel($event)"
                (keyup)="onTelUp($event)"
              />
              <div
                *ngIf="
                  checkoutForm.controls.phone.touched &&
                  checkoutForm.controls.phone.errors?.required
                "
              >
                <span class="warning-span">Telefone é obrigatório.</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.phone.touched &&
                  checkoutForm.controls.phone.errors?.pattern
                "
              >
                <span class="warning-span">Telefone deve ser numérico.</span>
              </div>
            </div>

            <!-- Email -->
            <div class="input-wrapper input-email">
              <label for="email" class="form-label">*Email</label>
              <input
                type="text"
                name="email"
                [formControl]="checkoutForm.controls['email']"
                value=""
                placeholder=""
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.email.touched &&
                  checkoutForm.controls.email.errors?.required
                "
              >
                <span class="warning-span">Email é obrigatório.</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.email.touched &&
                  checkoutForm.controls.email.errors?.email
                "
              >
                <span class="warning-span">Email inválido.</span>
              </div>
            </div>
          </form>
        </div>

        <!-- Adress Form -->
        <div class="address-form-s">
          <h4 class="form-title">Endereço de fatura</h4>
          <form [formGroup]="form" class="form form-grid grid-address">
            <!-- CEP -->
            <div class="input-wrapper input-cep">
              <label for="postalcode" class="form-label">*CEP</label>
              <input
                [imask]="{ mask: '00000-000' }"
                [unmask]="true"
                type="text"
                name="postalcode"
                [formControl]="checkoutForm.controls['postalcode']"
                value=""
                placeholder=""
                autocomplete="off"
                (keyup)="verifyCep(ccCode)"
              />
              <div
                *ngIf="
                  checkoutForm.controls.postalcode.touched &&
                  checkoutForm.controls.postalcode.errors?.required
                "
              >
                <span class="warning-span">CEP é obrigatório</span>
              </div>
            </div>

            <!-- País -->
            <div class="input-wrapper input-pais">
              <label for="country" class="form-label">*País</label>
              <select
                name="country"
                [formControl]="checkoutForm.controls['country']"
                class="form-select"
              >
                <option value="Brasil">Brasil</option>
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
              <div
                *ngIf="
                  checkoutForm.controls.country.touched &&
                  checkoutForm.controls.country.errors?.required
                "
              >
                <span class="warning-span">País é obrigatório.</span>
              </div>
            </div>

            <!-- Rua -->
            <div class="input-wrapper input-rua">
              <label for="address" class="form-label">*Rua</label>
              <input
                type="text"
                name="address"
                [formControl]="checkoutForm.controls['address']"
                value=""
                placeholder=""
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.address.touched &&
                  checkoutForm.controls.address.errors?.required
                "
              >
                <span class="warning-span">Endereço é obrigatório</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.address.touched &&
                  checkoutForm.controls.address.errors?.maxlength
                "
              >
                <span class="warning-span">No máximo 500 caracteres.</span>
              </div>
            </div>

            <!-- Número -->
            <div class="input-wrapper input-numero">
              <label for="stnumber" class="form-label">*Número</label>
              <input
                type="text"
                name="stnumber"
                [formControl]="checkoutForm.controls['number']"
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.address.touched &&
                  checkoutForm.controls.address.errors?.required
                "
              >
                <span class="warning-span">Número é obrigatório</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.address.touched &&
                  checkoutForm.controls.address.errors?.maxlength
                "
              >
                <span class="warning-span">No máximo 500 caracteres.</span>
              </div>
            </div>

            <!-- Complemento -->
            <div class="input-wrapper input-complemento">
              <label for="complement" class="form-label">Complemento</label>
              <input
                type="text"
                name="complement"
                [formControl]="checkoutForm.controls['complement']"
                autocomplete="off"
              />
            </div>

            <!-- Bairro -->
            <div class="input-wrapper input-bairro">
              <label for="address" class="form-label">*Bairro</label>
              <input
                type="text"
                name="address"
                [formControl]="checkoutForm.controls['bairro']"
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.address.touched &&
                  checkoutForm.controls.address.errors?.required
                "
              >
                <span class="warning-span">Bairro é obrigatório</span>
              </div>
              <div
                *ngIf="
                  checkoutForm.controls.address.touched &&
                  checkoutForm.controls.address.errors?.maxlength
                "
              >
                <span class="warning-span">No máximo 500 caracteres.</span>
              </div>
            </div>

            <!-- Cidade -->
            <div class="input-wrapper input-cidade">
              <label for="town" class="form-label">*Cidade</label>
              <input
                type="text"
                name="town"
                [formControl]="checkoutForm.controls['town']"
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.town.touched &&
                  checkoutForm.controls.town.errors?.required
                "
              >
                <span class="warning-span">Cidade é obrigatório</span>
              </div>
            </div>

            <!-- Estado -->
            <div class="input-wrapper input-estado">
              <label for="state" class="form-label">*Estado</label>
              <input
                type="text"
                name="state"
                [formControl]="checkoutForm.controls['state']"
                autocomplete="off"
              />
              <div
                *ngIf="
                  checkoutForm.controls.state.touched &&
                  checkoutForm.controls.state.errors?.required
                "
              >
                <span class="warning-span">Estado é obrigatório</span>
              </div>
            </div>

            <span class="warning-span">*Campo obrigatório</span>
          </form>
        </div>
      </section>

      <!-- Summary -->
      <section class="checkout-order-summary">
        <div class="summary-row summary-titles">
          <h4 class="form-title">Resumo do pedido</h4>
          <h4 class="form-title"></h4>
        </div>

        <div class="summary-row summary-products">
          <!-- Cart full -->
          <div class="summary-products-list" *ngIf="checkOutItems.length">
            <!-- Pix not available -->
            <div
              class="prod-pix-not-available summary-product-row"
              *ngIf="PaymentMethod != '3'"
            >
              <div
                class="summary-product-row-item"
                *ngFor="let item of checkOutItems"
              >
                <p>{{ item.product.name }} × {{ item.quantity }}</p>

                <div class="summary-product-price">
                  <p>
                    {{
                      item.product.price * item.quantity
                        | currency : item.product.moedaCode
                    }}
                  </p>
                  <div
                    class="convert-arrow"
                    *ngIf="item.product.moedaCode != 'BRL'"
                  >
                    <img src="/assets/images/arrow-right.png" alt="" />
                  </div>
                  <p *ngIf="item.product.moedaCode != 'BRL'">
                    {{
                      item.product.price
                        | calculateBRValue : item.product.moeda
                        | async
                        | currency : "BRL"
                    }}
                  </p>
                </div>
              </div>
            </div>

            <!-- Pix available -->
            <div
              class="prod-pix-available summary-product-row"
              *ngIf="PaymentMethod == '3'"
            >
              <div
                class="summary-product-row-item"
                *ngFor="let item of checkOutItems"
              >
                <p>{{ item.product.name }} × {{ item.quantity }}</p>
                <p>
                  {{
                    (item.product.paymentValues["pix"]
                      ? item.product.paymentValues["pix"]
                      : item.product.price) * item.quantity
                      | currency : item.product.moedaCode
                  }}
                </p>

                <p *ngIf="item.product.moedaCode != 'BRL'">
                  {{
                    item.product.paymentValues["pix"]
                      ? item.product.paymentValues["pix"]
                      : (item.product.price
                        | calculateBRValue : item.product.moeda
                        | async
                        | currency : "BRL")
                  }}
                </p>
              </div>
            </div>
          </div>

          <!-- Cart empty -->
          <div class="qty" *ngIf="!checkOutItems.length">
            <p class="empty-checkout">Nenhum produto no carrinho</p>
          </div>
        </div>

        <div class="summary-row summary-subtotal">
          <p>Subtotal</p>
          <p>{{ getTotal() | async | currency : "BRL" }}</p>
        </div>

        <div class="summary-row summary-discount" *ngIf="cuponsValidos">
          <div class="discount-amount">
            <p>Desconto cupom:</p>
            <p>{{ getDescontoCupom() | async | currency : "BRL" }}</p>
          </div>
          <div class="discount-coupon">
            <p>Cupom aplicado: {{ cuponsValidos.code }}</p>
            <button
              class="btn-link"
              (click)="cuponsValidos = null"
              [disabled]="isLoading"
            >
              Remover
            </button>
          </div>
        </div>

        <div class="summary-row summary-total" *ngIf="cuponsValidos">
          <p>Total</p>
          <p>
            {{
              (boleto
                ? (getTotal() | async) -
                  (getDescontoBoleto() | async) -
                  (getDescontoCupom() | async)
                : (getTotal() | async) - (getDescontoCupom() | async)
              ) | currency : "BRL"
            }}
          </p>
        </div>

        <div class="summary-row summary-total" *ngIf="!cuponsValidos">
          <p>Total</p>
          <p>
            {{
              (boleto
                ? (getTotal() | async) - (getDescontoBoleto() | async)
                : (getTotal() | async)
              ) | currency : "BRL"
            }}
          </p>
        </div>

        <div class="summary-row summary-coupon form">
          <div class="input-wrapper">
            <label for="cupom" class="form-label">Cupom</label>
            <input
              type="text"
              name="address"
              [(ngModel)]="cupon"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>

          <div class="coupon-btn">
            <button
              class="btn btn-secondary"
              (click)="adicionarCupon()"
              [disabled]="isLoading"
            >
              Adicionar cupom
            </button>
          </div>
        </div>

        <div class="summary-payment-method" *ngIf="checkOutItems.length">
          <!-- Radio buttons -->
          <div class="payment-options" *ngIf="!isTotalNull">
            <mat-radio-group
              aria-label="Selecione metodo de pagamento"
              class="payment-method-group"
            >
              <mat-radio-button
                class="radio-label"
                *ngIf="hasCardOption"
                value="CREDIT_CARD"
                (change)="changePaymentMethod($event)"
                >Cartão</mat-radio-button
              >
              <mat-radio-button
                class="radio-label"
                *ngIf="hasBoletoOption"
                value="2"
                (change)="changePaymentMethod($event)"
                >Boleto</mat-radio-button
              >
              <mat-radio-button
                class="radio-label"
                *ngIf="hasPixOption"
                value="3"
                (change)="changePaymentMethod($event)"
                >Pix</mat-radio-button
              >
            </mat-radio-group>
          </div>

          <div class="summary-payment-method-info">
            <!-- Selecionado Pix -->
            <div class="pix-form" *ngIf="PaymentMethod == '3'">
              <div class="pix-form-content" *ngIf="pixPaymentId !== ''">
                <p>
                  Aguardando pagamento. Transações pix podem demorar alguns
                  minutos para serem procesadas.
                </p>
                <p>Tempo até o código expirar: {{ pixRemainTimer }}</p>
                <img
                  class="pix-img"
                  style="align-content: center"
                  [src]="pixImg"
                  alt="Gerando código pix..."
                />
                <p>Pix copia e cola:</p>
                <div class="pix-code">
                  <p>{{ pixCopyPaste }}</p>
                </div>
                <button
                  class="btn btn-secondary"
                  type="button"
                  (click)="onPixCopyPasteClicked()"
                >
                  Copiar
                </button>
              </div>
            </div>

            <!-- Seleção de Parcelas -->
            <div
              class="input-mat-form"
              *ngIf="
                boleto == false && PaymentMethod !== '3' && PaymentMethod !== ''
              "
            >
              <mat-form-field
                class="mat-form-field"
                *ngIf="PaymentMethod == 'CREDIT_CARD'"
              >
                <mat-label class="mat-form-label">Número de Parcelas</mat-label>
                <mat-select
                  class="mat-form-select"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="parcelaSelecionada"
                >
                  <mat-option
                    class="mat-form-option"
                    *ngFor="let parcela of parcelas"
                    [value]="parcela"
                  >
                    {{ parcela.parcelas + 1 }}x{{
                      parcela.valor / (parcela.parcelas + 1)
                        | calculateBRValue : parcela.moeda
                        | async
                        | currency : "BRL"
                    }}
                    <span *ngIf="showJuros">
                      - Com {{ parcela.taxa | number : "1.0-2" }}% de Juros
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field
                class="mat-form-field"
                *ngIf="PaymentMethod == '2'"
              >
                <mat-label class="mat-form-label">Número de Parcelas</mat-label>
                <mat-select
                  class="mat-form-select"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="parcelaSelecionada"
                >
                  <mat-option
                    class="mat-form-option"
                    *ngFor="let parcela of parcelasBoleto"
                    [value]="parcela"
                  >
                    {{ parcela.parcelas + 1 }}x{{
                      parcela.valor / (parcela.parcelas + 1)
                        | calculateBRValue : parcela.moeda
                        | async
                        | currency : "BRL"
                    }}
                    <span *ngIf="showJuros">
                      - Com {{ parcela.taxa | number : "1.0-2" }}% de Juros
                    </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- AGREEMENT CHECKBOX -->
            <div
              class="card-agreement"
              *ngIf="!isTotalNull && PaymentMethod === 'CREDIT_CARD'"
            >
              <div class="input-wrapper">
                <input
                  type="checkbox"
                  name="agreement-asaas"
                  id="agreement-asaas"
                  [(ngModel)]="asaasAgreed"
                />
                <label for="agreement-asaas"
                  >Estou ciente que o pagamento será realizado por uma
                  plataforma de pagamento externa. Clique no botão abaixo para
                  abrir e iniciar a transação.</label
                >
              </div>
            </div>

            <!-- Button de pagamento no cartão -->
            <div
              class="pay-btn"
              *ngIf="!isTotalNull && PaymentMethod === 'CREDIT_CARD'"
            >
              <button
                class="btn-secondary btn asaas-card-btn"
                [class.disabled-btn]="isPurchaseDisabled()"
                type="button"
                (click)="paymentButton()"
                [disabled]="!asaasAgreed"
              >
                Proceder pagamento
              </button>
            </div>

            <!-- Button de pagamento no boleto -->
            <div class="pay-btn" *ngIf="!isTotalNull && PaymentMethod === '2'">
              <button
                class="btn-secondary btn"
                [class.disabled-btn]="isPurchaseDisabled()"
                type="button"
                (click)="paymentButton()"
              >
                Pagar
              </button>
            </div>

            <!-- Selecionado Pix -->
            <div
              class="pay-btn"
              *ngIf="PaymentMethod === '3' && pixPaymentLink === ''"
            >
              <button
                class="btn-secondary btn"
                [disabled]="
                  checkoutForm.invalid || checkContracts() || isLoading
                "
                type="button"
                (click)="redirectToPixPayment()"
              >
                Pagar com Pix
              </button>
              <p *ngIf="checkoutForm.invalid" style="color: red">
                Preencha todos os dados para prosseguir ao pagamento
              </p>
            </div>

            <div class="pay-btn" *ngIf="isTotalNull">
              <button
                class="btn-secondary btn"
                [disabled]="
                  checkoutForm.invalid ||
                  checkContracts() ||
                  dontHaveInstallmentsSelected()
                "
                type="button"
                (click)="finalizarFree()"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>

  <!-- Contracts -->
  <section class="contracts-s" *ngIf="contratos && contratos.length > 0">
    <div class="container">
      <h4 class="title">Contratos</h4>
      <form class="product-contract" [formGroup]="contractForm">
        <ng-container *ngFor="let item of contratos" class="contract">
          <div class="agreement-content" [innerHTML]="item.contrato"></div>
          <div class="contract-agreement-input">
            <input
              type="checkbox"
              [formControlName]="item.id"
              [value]="true"
              title="Leia e aceite os termos de contrato"
              class="checkbox"
            />
            <label>Leia e aceite os termos de contrato</label>
          </div>
        </ng-container>
      </form>
    </div>
  </section>
</div>

<!-- breadcrumb start -->
<!-- <div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h2>Checkout</h2>
        </div>
      </div>
      <div class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="''">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Checkout</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div> -->

<!-- breadcrumb End -->
<!-- TODO - arruamr validação2 -->
<!-- <section class="section-b-space">
  <div class="wrapper">
    <div class="overlay" *ngIf="isLoading">
      <div class="spinner-wrapper">
        <app-spinner></app-spinner>
      </div>
    </div>

    <div class="loaded-content" [class.blurred]="isLoading">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="container padding-cls">
    <div class="checkout-page">
      <div class="checkout-form">
        <form [formGroup]="form">
          <div class="row">
            <div class="col-lg-6 col-sm-12 col-xs-12">
              <div class="checkout-title">
                <h3>Detalhes de Pagamento</h3>
              </div>
              <div class="row check-out">
                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label">*Nome</div>
                  <input
                    type="text"
                    name="firstname"
                    [formControl]="checkoutForm.controls['firstname']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.firstname.touched &&
                      checkoutForm.controls.firstname.errors?.required
                    "
                    class="text text-danger"
                  >
                    Nome é obrigatório.
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.firstname.touched &&
                      checkoutForm.controls.firstname.errors?.pattern
                    "
                    class="text text-danger"
                  >
                    Nome deve somente conter letras.
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label">*Sobrenome</div>
                  <input
                    type="text"
                    name="lastname"
                    [formControl]="checkoutForm.controls['lastname']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.lastname.touched &&
                      checkoutForm.controls.lastname.errors?.required
                    "
                    class="text text-danger"
                  >
                    Sobrenome é obrigatório.
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.lastname.touched &&
                      checkoutForm.controls.lastname.errors?.pattern
                    "
                    class="text text-danger"
                  >
                    Sobrenome deve somente conter letras.
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label">*CPF</div>
                  <input
                    type="text"
                    [imask]="{ mask: '000.000.000-00' }"
                    [unmask]="true"
                    [formControl]="checkoutForm.controls['document']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.lastname.touched &&
                      checkoutForm.controls.lastname.errors?.required
                    "
                    class="text text-danger"
                  >
                    CPF é obrigatório.
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label">*Telefone</div>
                  <input
                    id="input_tel"
                    [imask]="telMask"
                    [unmask]="true"
                    type="text"
                    name="phone"
                    [formControl]="checkoutForm.controls['phone']"
                    autocomplete="off"
                    (keydown)="onTel($event)"
                    (keyup)="onTelUp($event)"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.phone.touched &&
                      checkoutForm.controls.phone.errors?.required
                    "
                    class="text text-danger"
                  >
                    Telefone é obrigatório.
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.phone.touched &&
                      checkoutForm.controls.phone.errors?.pattern
                    "
                    class="text text-danger"
                  >
                    Telefone deve ser numérico.
                  </div>
                </div>
                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                  <div class="field-label">*Email</div>
                  <input
                    type="text"
                    name="email"
                    [formControl]="checkoutForm.controls['email']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.email.touched &&
                      checkoutForm.controls.email.errors?.required
                    "
                    class="text text-danger"
                  >
                    Email é obrigatório.
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.email.touched &&
                      checkoutForm.controls.email.errors?.email
                    "
                    class="text text-danger"
                  >
                    Email inválido.
                  </div>
                </div>
              </div>

              <div class="checkout-title">
                <h3>Endereço de fatura</h3>
              </div>
              <div class="row check-out" *ngIf="enderecos.length != 0">
                <mat-radio-group
                  name="endercos"
                  [(ngModel)]="isCustomEndereco"
                  (change)="changeEndereco()"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-radio-button [value]="false">
                    Usar endereço padrão</mat-radio-button
                  >
                  <br />
                  <mat-radio-button [value]="true">
                    Usar outro endereço</mat-radio-button
                  >
                </mat-radio-group>
              </div>

              <div class="row check-out" *ngIf="!isCustomEndereco">
                <mat-radio-group
                  name="chooseenderecos"
                  [(ngModel)]="enderecoId"
                  (change)="changeEndereco()"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <mat-radio-button
                    [value]="enderecoFatura"
                    *ngFor="let enderecoFatura of enderecos"
                  >
                    {{ enderecoFatura.address }} ,
                    {{ enderecoFatura.number }}-{{ enderecoFatura.town }},{{
                      enderecoFatura.state
                    }}, {{ enderecoFatura.country }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <div
                class="row check-out"
                *ngIf="isCustomEndereco || enderecos.length == 0"
              >
                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                  <div class="field-label">*CEP</div>
                  <input
                    [imask]="{ mask: '00000-000' }"
                    [unmask]="true"
                    type="text"
                    name="postalcode"
                    [formControl]="checkoutForm.controls['postalcode']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                    (keyup)="verifyCep()"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.postalcode.touched &&
                      checkoutForm.controls.postalcode.errors?.required
                    "
                    class="text text-danger"
                  >
                    CEP é obrigatório
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label">*País</div>
                  <select
                    name="country"
                    [formControl]="checkoutForm.controls['country']"
                  >
                    <option value="Brasil">Brasil</option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </option>
                    <option value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <div
                    *ngIf="
                      checkoutForm.controls.country.touched &&
                      checkoutForm.controls.country.errors?.required
                    "
                    class="text text-danger"
                  >
                    País é obrigatório.
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label">*Rua</div>
                  <input
                    type="text"
                    name="address"
                    [formControl]="checkoutForm.controls['address']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.address.touched &&
                      checkoutForm.controls.address.errors?.required
                    "
                    class="text text-danger"
                  >
                    Endereço é obrigatório
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.address.touched &&
                      checkoutForm.controls.address.errors?.maxlength
                    "
                    class="text text-danger"
                  >
                    No máximo 500 caracteres.
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label">Complemento</div>
                  <input
                    type="text"
                    name="complement"
                    [formControl]="checkoutForm.controls['complement']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label">*Número</div>
                  <input
                    type="text"
                    name="stnumber"
                    [formControl]="checkoutForm.controls['number']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.address.touched &&
                      checkoutForm.controls.address.errors?.required
                    "
                    class="text text-danger"
                  >
                    Número é obrigatório
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.address.touched &&
                      checkoutForm.controls.address.errors?.maxlength
                    "
                    class="text text-danger"
                  >
                    No máximo 500 caracteres.
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label">*Bairro</div>
                  <input
                    type="text"
                    name="address"
                    [formControl]="checkoutForm.controls['bairro']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.address.touched &&
                      checkoutForm.controls.address.errors?.required
                    "
                    class="text text-danger"
                  >
                    Bairro é obrigatório
                  </div>
                  <div
                    *ngIf="
                      checkoutForm.controls.address.touched &&
                      checkoutForm.controls.address.errors?.maxlength
                    "
                    class="text text-danger"
                  >
                    No máximo 500 caracteres.
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                  <div class="field-label">*Cidade</div>
                  <input
                    type="text"
                    name="town"
                    [formControl]="checkoutForm.controls['town']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.town.touched &&
                      checkoutForm.controls.town.errors?.required
                    "
                    class="text text-danger"
                  >
                    Cidade é obrigatório
                  </div>
                </div>
                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                  <div class="field-label">*Estado</div>
                  <input
                    type="text"
                    name="state"
                    [formControl]="checkoutForm.controls['state']"
                    value=""
                    placeholder=""
                    autocomplete="off"
                  />
                  <div
                    *ngIf="
                      checkoutForm.controls.state.touched &&
                      checkoutForm.controls.state.errors?.required
                    "
                    class="text text-danger"
                  >
                    Estado é obrigatório
                  </div>
                </div>
                <div style="margin-top: 10px">*Campo obrigatório</div>
              </div>
            </div>

            /**** Frame de detalhes ***\
            <div class="col-lg-6 col-sm-12 col-xs-12">
              <div class="checkout-details">
                <div class="order-box">
                  <div class="title-box">
                    <div>Produto <span> Total</span></div>
                  </div>
                  <ul class="qty" *ngIf="checkOutItems.length">
                    <div *ngIf="PaymentMethod != '3'">
                      <li *ngFor="let item of checkOutItems">
                        {{ item.product.name }} × {{ item.quantity }}

                        <span class=" " *ngIf="item.product.moedaCode != 'BRL'">
                          {{
                            item.product.price
                              | calculateBRValue : item.product.moeda
                              | async
                              | currency : "BRL"
                          }}
                        </span>
                        <span>
                          {{
                            item.product.price * item.quantity
                              | currency : item.product.moedaCode
                          }}
                        </span>
                      </li>
                    </div>
                    <div *ngIf="PaymentMethod == '3'">
                      <li *ngFor="let item of checkOutItems">
                        {{ item.product.name }} × {{ item.quantity }}

                        <span class=" " *ngIf="item.product.moedaCode != 'BRL'">
                          {{
                            item.product.paymentValues["pix"]
                              ? item.product.paymentValues["pix"]
                              : (item.product.price
                                | calculateBRValue : item.product.moeda
                                | async
                                | currency : "BRL")
                          }}
                        </span>
                        <span>
                          {{
                            (item.product.paymentValues["pix"]
                              ? item.product.paymentValues["pix"]
                              : item.product.price) * item.quantity
                              | currency : item.product.moedaCode
                          }}
                        </span>
                      </li>
                    </div>
                  </ul>
                  <ul class="qty" *ngIf="!checkOutItems.length">
                    <li class="empty-checkout">Nenhum produto no carrinho</li>
                  </ul>

                  <ul class="sub-total">
                    <li>
                      Subtotal
                      <span class="count">{{
                        getTotal() | async | currency : "BRL"
                      }}</span>
                    </li>
                  </ul>

                  <ul class="sub-total" *ngIf="cuponsValidos">
                    <li>
                      Desconto Cupom:
                      <span class="count">{{
                        getDescontoCupom() | async | currency : "BRL"
                      }}</span>
                    </li>
                    <li>
                      Cupom Aplicado: {{ cuponsValidos.code }}
                      <button
                        class="btn-solid btn"
                        (click)="cuponsValidos = null"
                        [disabled]="isLoading"
                      >
                        remover
                      </button>
                    </li>
                  </ul>

                  <ul class="total" *ngIf="!cuponsValidos">
                    <li>
                      Total
                      <span class="count">{{
                        (boleto
                          ? (getTotal() | async) - (getDescontoBoleto() | async)
                          : (getTotal() | async)
                        ) | currency : "BRL"
                      }}</span>
                    </li>
                  </ul>

                  <ul class="total" *ngIf="cuponsValidos">
                    <li>
                      Total
                      <span class="count">{{
                        (boleto
                          ? (getTotal() | async) -
                            (getDescontoBoleto() | async) -
                            (getDescontoCupom() | async)
                          : (getTotal() | async) - (getDescontoCupom() | async)
                        ) | currency : "BRL"
                      }}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-sm-6 col-xs-6">
                    <div class="field-label">Cupom</div>
                    <input
                      type="text"
                      name="address"
                      [(ngModel)]="cupon"
                      [ngModelOptions]="{ standalone: true }"
                      value=""
                    />
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-6">
                    <button
                      class="btn-solid btn m-1"
                      style="margin-top: 34px !important"
                      type="button"
                      (click)="adicionarCupon()"
                      [disabled]="isLoading"
                    >
                      Adicionar Cupom
                    </button>
                  </div>
                </div>

                <div class="payment-box" *ngIf="checkOutItems.length">
                  <div class="upper-box">
                    <div class="payment-options" *ngIf="!isTotalNull">
                      <mat-radio-group
                        aria-label="Selecione metodo de pagamento"
                        class="payment-method-group"
                      >
                        <mat-radio-button
                          *ngIf="hasCardOption"
                          value="1"
                          (change)="changePaymentMethod($event)"
                          >Cartão</mat-radio-button
                        >
                        <mat-radio-button
                          *ngIf="hasBoletoOption"
                          value="2"
                          (change)="changePaymentMethod($event)"
                          >Boleto</mat-radio-button
                        >
                        <mat-radio-button
                          *ngIf="hasPixOption"
                          value="3"
                          (change)="changePaymentMethod($event)"
                          >Pix</mat-radio-button
                        >
                      </mat-radio-group>
                    </div>

                    <div *ngIf="PaymentMethod == '1'">
                      <div class="row">
                        <div class="form-group col-md-12 col-sm-6 col-xs-12">
                          <div class="field-label">Número do Cartão*</div>
                          <input
                            type="text"
                            name="numeroCartao"
                            [imask]="{ mask: '0000 0000 0000 0000' }"
                            [unmask]="true"
                            [formControl]="cardForm.controls['numeroCartao']"
                            value=""
                            placeholder="0000 0000 0000 0000"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              cardForm.controls.numeroCartao.touched &&
                              cardForm.controls.numeroCartao.errors?.required
                            "
                            class="text text-danger"
                          >
                            Numero do cartão é obrigatório
                          </div>
                        </div>
                        <div class="form-group col-md-12 col-sm-6 col-xs-12">
                          <div class="field-label">Nome*</div>
                          <input
                            type="text"
                            name="nome"
                            [formControl]="cardForm.controls['nome']"
                            value=""
                            placeholder="Como está no cartão"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              cardForm.controls.nome.touched &&
                              cardForm.controls.nome.errors?.required
                            "
                            class="text text-danger"
                          >
                            Nome é obrigatório
                          </div>
                        </div>
                        <div class="form-group col-md-12 col-sm-6 col-xs-12">
                          <div class="field-label">Validade*</div>
                          <input
                            type="text"
                            name="validade"
                            [imask]="{ mask: '00/00' }"
                            [unmask]="true"
                            [formControl]="cardForm.controls['validade']"
                            value=""
                            placeholder="MM/AA"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              cardForm.controls.validade.touched &&
                              cardForm.controls.validade.errors?.required
                            "
                            class="text text-danger"
                          >
                            Validade é obrigatório
                          </div>
                        </div>
                        <div class="form-group col-md-12 col-sm-6 col-xs-12">
                          <div class="field-label">Codigo de Segurança*</div>
                          <input
                            *ngIf="currentBrand != 'Amex'"
                            type="text"
                            name="cvv"
                            [imask]="{ mask: '000' }"
                            [unmask]="true"
                            [formControl]="cardForm.controls['cvv']"
                            value=""
                            placeholder="CVV"
                            autocomplete="off"
                          />
                          <input
                            *ngIf="currentBrand == 'Amex'"
                            type="text"
                            name="cvv"
                            [imask]="{ mask: '0000' }"
                            [unmask]="true"
                            [formControl]="cardForm.controls['cvv']"
                            value=""
                            placeholder="CVV"
                            autocomplete="off"
                          />
                          <div
                            *ngIf="
                              cardForm.controls.cvv.touched &&
                              cardForm.controls.cvv.errors?.required
                            "
                            class="text text-danger"
                          >
                            Codigo de segurança é obrigatório
                          </div>
                        </div>
                        <mat-form-field>
                          <mat-label>Bandeira do cartão</mat-label>
                          <mat-select (selectionChange)="changeBrand($event)">
                            <mat-option
                              *ngFor="let brand of brands"
                              [value]="brand"
                              >{{ brand }}</mat-option
                            >
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div *ngIf="PaymentMethod == '3'">
                      <div *ngIf="pixPaymentId !== ''">
                        <p>
                          Aguardando pagamento. Transações pix podem demorar
                          alguns minutos para serem procesadas.
                        </p>
                        <p>Tempo até o código expirar: {{ pixRemainTimer }}</p>
                        <img
                          style="align-content: center"
                          [src]="pixImg"
                          alt="Gerando código pix..."
                        />
                        <p>Pix copia e cola:</p>
                        <div style="border: 1px solid black; padding: 10px">
                          <span
                            style="
                              display: inline-block;
                              text-align: center;
                              max-width: 100%;
                              overflow: hidden;
                            "
                            >{{ pixCopyPaste }}</span
                          >
                          <button
                            class="btn-solid btn"
                            type="button"
                            (click)="onPixCopyPasteClicked()"
                          >
                            Copiar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="boleto == false && PaymentMethod !== '3'">
                  <mat-form-field>
                    <mat-label>Número de Parcelas</mat-label>
                    <mat-select
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="parcelaSelecionada"
                    >
                      <mat-option
                        *ngFor="let parcela of parcelas"
                        [value]="parcela"
                      >
                        {{ parcela.parcelas + 1 }}x{{
                          parcela.valor / (parcela.parcelas + 1)
                            | calculateBRValue : parcela.moeda
                            | async
                            | currency : "BRL"
                        }}
                        <span *ngIf="showJuros"
                          >- Com {{ parcela.taxa | number : "1.0-2" }}% de
                          Juros</span
                        >
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div
                  class="text-right"
                  *ngIf="!isTotalNull && PaymentMethod !== '3'"
                >
                  <button
                    class="btn-solid btn"
                    [disabled]="
                      checkoutForm.invalid ||
                      checkContracts() ||
                      dontHaveInstallmentsSelected() ||
                      isLoading ||
                      PaymentMethod == '' ||
                      (PaymentMethod == 1 && cardForm.invalid)
                    "
                    type="button"
                    (click)="paymentButton()"
                  >
                    pagamento
                  </button>
                </div>
                <div
                  class="text-right"
                  *ngIf="PaymentMethod === '3' && pixPaymentId === ''"
                >
                  <button
                    class="btn-solid btn"
                    [disabled]="
                      checkoutForm.invalid || checkContracts() || isLoading
                    "
                    type="button"
                    (click)="pixGenerateButton()"
                  >
                    Gerar QR Code
                  </button>
                </div>

                <div class="text-right" *ngIf="isTotalNull">
                  <button
                    class="btn-solid btn"
                    [disabled]="
                      checkoutForm.invalid ||
                      checkContracts() ||
                      dontHaveInstallmentsSelected()
                    "
                    type="button"
                    (click)="finalizarFree()"
                  >
                    Finalizar
                  </button>
                </div>
                <a class="pay-button-getnet"></a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section> -->

<!-- Contrato -->
<!-- <section
  class="section-b-space contract"
  *ngIf="contratos && contratos.length > 0"
>
  <div class="container padding-cls">
    <div class="checkout-page">
      <div class="checkout-form">
        <div class="row">
          <div class="col-lg-6 col-sm-12 col-xs-12">
            <div clss="checkout-title">
              <b>Contratos</b>
            </div>
          </div>
        </div>
        <form [formGroup]="contractForm">
          <ng-container *ngFor="let item of contratos">
            <div class="row view-contract">
              <div class="agreement-content" [innerHTML]="item.contrato"></div>
            </div>
            <div class="row agree-checkbox">
              <p class="agree">
                <input
                  type="checkbox"
                  [formControlName]="item.id"
                  [value]="true"
                  title="Leia e aceite os termos de contrato"
                  class="checkbox"
                /><span>Leia e aceite os termos de contrato</span>
              </p>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </div>
</section> -->
