<footer>
  <div class="container">
    <div class="info footer-txt">
      <h2 class="footer-div-heading">Informações</h2>
      <app-info-nav-list></app-info-nav-list>
    </div>
    <div class="help">
      <h2 class="footer-div-heading">Precisa de ajuda?</h2>
      <app-help-contact></app-help-contact>
    </div>
    <div class="terms">
      <h2 class="footer-div-heading">Termos e dados</h2>
      <app-terms></app-terms>
    </div>
    <div class="footer-logo">
      <div class="logo">
        <img src="assets/images/VESSEL-STORE.png" alt="Logo Vessel Store">
      </div>
    </div>
  </div>
</footer>