import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FrontPageService {

  headers = {'content-type' : 'application/json'};
  baseUrl = 'https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-menu/';

  constructor(private http:HttpClient) { }

  getCarousel(){
    return this.getCarouselReq();
  }

  private getCarouselReq() {
    let url: string = this.baseUrl + "main-carousel";
    console.log(`Getting url: ${url}`);
    console.log(`base url: ${this.baseUrl}`);

    return this.http.get<any>(url, {'headers': this.headers});
  }

  getDestaque(){
    return this.getDestaquesReq();
  }

  private getDestaquesReq() {
    let url: string = this.baseUrl + "destaque-carousel";

    return this.http.get<any>(url, {'headers': this.headers});
  }

  getProdutoDestaque(){
    return this.getProdutoDestaqueReq();
  }
 
  private getProdutoDestaqueReq() {
    let url: string = this.baseUrl + "destaque-products";

    return this.http.get<any>(url, {'headers': this.headers});
  }
}
