import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Product } from "src/app/shared/classes/product";
import { AuthenticationService } from "src/app/shared/services/authentication.service";
import { CartService } from "src/app/shared/services/cart.service";
import { ProductsService } from "src/app/shared/services/products.service";

@Component({
  selector: "app-product-description",
  templateUrl: "./product-description.component.html",
  styleUrls: ["./product-description.component.css"],
})
export class ProductDescriptionComponent implements OnInit {
  isLoading: boolean = false;
  selectedTab: number = 1;
  prodId: string = "";
  productData: Product = null;
  genericForm: FormGroup = this.fb.group({});
  showTurmas: boolean = false;
  turmaNome: string = "";
  public filesToUpload = {};
  carouselConfig = {
    autoplay: false,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    arrows: true,
    dots: true,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductsService,
    private fb: FormBuilder,
    private cartService: CartService,
    private authService: AuthenticationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.paramMap.subscribe((params) => {
      this.prodId = params.get("id");
      this.SetupPage();
    });
    this.isLoading = false;
  }

  private SetupPage() {
    this.productService.getProduct(this.prodId).subscribe((product) => {
      this.productData = product;

      // Split name in two parts
      // if(this.productData.name){
      //   const nameSplit = this.productData.name.split(' ');
      //   this.productData.name = nameSplit[0];
      //   (this.productData as any).highlight = nameSplit.slice(1).join(' ');
      // }

      let campos = [];
      Object.keys(this.genericForm.controls).forEach((campo) => {
        campos.push(campo);
      });
      if (campos.length > 0) {
        campos.forEach((campo) => {
          this.genericForm.removeControl(campo);
          if (campo.tipo == "Multi") {
            campo.multiValores = campo.multiValores.split(";");
          }
        });
      }

      if (
        (this.elementExists(product["hasTurmas"]) &&
          this.elementExists(product["turmas"]) &&
          product.hasTurmas == true &&
          product.turmas.length > 0) ||
        (this.elementExists(product["turmas"]) && product.turmas.length > 0)
      ) {
        this.showTurmas = true;
      } else {
        this.showTurmas = false;
      }
      this.turmaNome = product["turmasNome"]
        ? product["turmasNome"]
        : "Modalidades";

      if (this.productData.campos) {
        this.productData.campos.forEach((campo) => {
          if (campo.tipo == "Multi") {
            campo.multiValores = campo.multiValores.split(";");
            if (campo.multiValores[campo.multiValores.length - 1] == "") {
              campo.multiValores.splice(campo.multiValores.length - 1, 1);
            }
          }
        });
      }
      if (this.productData["active"]) {
        if (this.productData["active"] == "false") {
          this.router.navigate([""]);
          return;
        }
      }
      if (this.productData["campos"]) {
        const group: any = {};
        this.productData["campos"].forEach((campo) => {
          let newValidators = [];

          // Add validators
          // Exception may be cause on old products
          if (campo["required"] == null && campo["required"] == undefined) {
            campo = { ...campo, required: true };
          }

          if (campo.required) {
            newValidators.push(Validators.required);
          }
          const tipo: string = campo.tipo.toLowerCase();
          if (tipo == "cpf") {
            newValidators.push(Validators.minLength(14));
            newValidators.push(Validators.maxLength(14));
          } else if (tipo == "data") {
            newValidators.push(Validators.minLength(10));
            newValidators.push(Validators.maxLength(10));
          } else if (tipo == "telefone") {
            newValidators.push(Validators.minLength(14));
            newValidators.push(Validators.maxLength(15));
          }

          // Add the field
          group[campo.nome] = new FormControl("", newValidators);
        });
        this.genericForm = new FormGroup(group);
      }
      if (this.productData["turmas"]) {
        if (!this.productData["hasTurmas"]) {
          this.genericForm.addControl("turma", new FormControl(""));
        } else {
          this.genericForm.addControl(
            "turma",
            new FormControl("", Validators.required)
          );
        }
      }
      if (this.productData["estoques"]) {
        this.genericForm.addControl(
          "estoques",
          new FormControl("", Validators.required)
        );
      }
    });
  }

  public BuyNow(product: Product, quantity) {
    if (this.genericForm.invalid) {
      const invalidFields = [];
      for (const field in this.genericForm.controls) {
        if (this.genericForm.controls[field].invalid) {
          invalidFields.push(field);
        }
      }
      const invalidFieldsStr = invalidFields.join(", ");
      this.toastr.error(
        `Atenção, preencha todos os dados antes de adicionar ao carrinho. Dados faltando: ${invalidFieldsStr}`
      );
      return;
    }

    if (!this.addProductToCart(this.productData, 1)) {
      return;
    }
    this.authService.isLoggedIn().subscribe((isLogged) => {
      if (!isLogged) {
        this.router.navigate(["/pages/login"]);
      } else {
        this.router.navigate(["/home/checkout"]);
      }
    });
  }

  public AddToCart(product: Product, quantity) {
    if (this.genericForm.invalid) {
      const invalidFields = [];
      for (const field in this.genericForm.controls) {
        if (this.genericForm.controls[field].invalid) {
          invalidFields.push(field);
        }
      }
      const invalidFieldsStr = invalidFields.join(", ");
      this.toastr.error(
        `Atenção, preencha todos os dados antes de adicionar ao carrinho. Dados faltando: ${invalidFieldsStr}`
      );
      return;
    }

    if (this.addProductToCart(this.productData, 1)) {
      return;
    }
    this.authService.isLoggedIn().subscribe((isLogged) => {
      if (!isLogged) {
        this.router.navigate(["/pages/login"]);
      }
    });
  }

  addProductToCart(product: Product, quantity): boolean {
    // Check if the turma has enough stock
    if (this.productData["turmas"]) {
      const turma = this.genericForm.value["turma"];
      const estoque = this.productData["turmas"].find(
        (turmaEstoque) => turmaEstoque.nome == turma
      );
      if (estoque && estoque.vagasOcupadas >= estoque.vagas) {
        this.toastr.error(
          `Atenção! ${this.turmaNome} ${turma} não possui estoque suficiente para a quantidade desejada.`
        );
        return false;
      }
    }

    if (
      this.productData["campos"] ||
      this.productData["turmas"] ||
      this.productData["estoques"]
    ) {
      let aux_form = this.genericForm.value;
      if (product.estoques) {
        aux_form["tamanho"] = aux_form["estoques"];
        delete aux_form["estoques"];
      }
      this.productData["camposCustomizados"] = this.genericForm.value;
    }
    if (quantity == 0) return false;
    this.cartService.addToCart({ ...product }, parseInt(quantity));
    return true;
  }

  // Toggle active tab
  setActiveTab(num) {
    this.selectedTab = num;
  }

  isActive(num) {
    return this.selectedTab === num;
  }

  elementExists(element: any): Boolean {
    return element !== null && element !== Boolean;
  }
}
