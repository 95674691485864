import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { find, map } from 'rxjs/operators';
import { CurrencyService } from '../service/currency.service';

@Pipe({
  name: 'calculateBRValue',
  pure:false
})
export class CalculateBRValuePipe implements PipeTransform {
  private currencies
  constructor(
    private currencyService: CurrencyService
  ){
     currencyService.getCurrencies().subscribe((currencies)=>{
      this.currencies=currencies;
    });
  }

  transform(value: number, currencyId: string): Observable<number> {
    return this.currencyService.getCurrencies().pipe(
      map(currencies => {
        const currency = currencies.find(c => c.id === currencyId);
        if (currency) {
          return value * currency.ValorEmReal;
        } else {
          return value;
        }
      })
    );
  }

}
