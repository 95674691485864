
    <div class="modal-boleto" style="position: relative; z-index: 1;">
      <h1 mat-dialog-title>Confirmar</h1>
      <div mat-dialog-content class="text">
        Gerar boletos? O não pagamento das parcelas, pode acarretar em multa e
        juros em caso de atraso após a data de vencimento, e em protesto
        cartorial em caso de inadimplência.
      </div>
      <div mat-dialog-actions class="buttons">
        <button class="btn btn-secondary" mat-button (click)="onConfirm()">
          Gerar Boleto
        </button>
        <button class="btn btn-secondary" mat-button [mat-dialog-close]="false">
          Cancelar
        </button>
      </div>
    </div>
  