import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  CategoriaService,
  ICategory,
} from "src/app/shared/services/categoria.service";
import { MinibannerService } from "src/app/shared/services/minibanner.service";
import { ProductsService } from "src/app/shared/services/products.service";
import { Product } from "src/app/shared/classes/product";
import { Subscription } from "rxjs";
import {
  CurrencyService,
  ICurrency,
} from "src/app/shared/service/currency.service";

@Component({
  selector: "app-product-category-list",
  templateUrl: "./product-category-list.component.html",
  styleUrls: ["./product-category-list.component.css"],
})
export class ProductCategoryListComponent implements OnInit {
  allCategoryBanners: any[] = [];
  categoriesWithImg: any[];
  currentCategoryBanner: any[];
  id: string = "";
  inputVisible: boolean = false;
  isLoading: boolean = true;
  moreCategories: any[];
  selectVisible: boolean = false;
  products: Product[];
  slides = [];
  sortByOrder: string = "ordem";

  @ViewChild("searchInput") searchInput: any;
  @ViewChild("select") select!: ElementRef;
  @ViewChild("label") label!: ElementRef;
  @ViewChild("dropdownIcon") dropdownIcon!: ElementRef;

  private paramsSubscription: Subscription;
  private currencies: ICurrency[];
  private allCategories: ICategory[];

  constructor(
    private router: Router,
    private categoryService: CategoriaService,
    private activatedRoute: ActivatedRoute,
    private bannerService: MinibannerService,
    private productService: ProductsService,
    private currencyService: CurrencyService,
    private categoriaService: CategoriaService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.currencyService.getCurrencies().subscribe((currencies) => {
      this.currencies = currencies;
      this.categoriaService.getCategorias().subscribe((allCategories) => {
        this.allCategories = allCategories;
        this.activatedRoute.paramMap.subscribe((params) => {
          this.id = params.get("id");
          this.setupCategoryPage();
        });
      });
    });
  }

  setupCategoryPage() {
    this.bannerService.getBanners().subscribe(
      (allCategoryBanners) => {
        this.allCategoryBanners = allCategoryBanners;

        this.currentCategoryBanner = this.allCategoryBanners
          .filter((category) => category.categoryId === this.id)
          .map((category) => {
            const nameWords = category.name ? category.name.split(" ") : [];
            return {
              ...category,
              name: nameWords[0],
              highlight: nameWords.splice(1).join(" "),
            };
          });

        this.moreCategories = this.allCategoryBanners
          .filter((category) => category.categoryId !== this.id)
          .map((category) => {
            const nameWords = category.name ? category.name.split(" ") : [];
            return {
              ...category,
              name: nameWords[0],
              highlight: nameWords.slice(1).join(" "),
            };
          });

        if (this.id !== null && this.id !== "") {
          this.productService
            .getProductByCategory(this.id)
            .subscribe((products) => {
              this.products = products.filter(
                (product) =>
                  product.active === "true" && product.status === "true"
              );
              this.fixOrder();

              this.slides = this.products.map((product) => {
                // const displayValue = product.selectedCategories.some((selectedCategory) => {
                //   const category = this.allCategories.find((cat) => cat.id === selectedCategory.id);
                //   return category && category.displayValue === true;
                // });
                const displayValue = this.allCategories.find(
                  (x) => x.id === this.id
                ).displayValue;

                let price = "";
                let currencySymbol = "";

                if (displayValue) {
                  const currencyCode = this.currencies.find(
                    (x) => x.id === product.moeda
                  ).Code;
                  const currencySymbols = {
                    BRL: "R$",
                    USD: "US$",
                    EUR: "€",
                  };
                  currencySymbol =
                    currencySymbols[currencyCode] || currencyCode;
                  price = product.price.toString();
                }

                return {
                  image:
                    product.photos && product.photos[0]
                      ? product.photos[0]
                      : "",
                  name: product.name,
                  price: price,
                  code: currencySymbol,
                  productData: product,
                };
              });

              this.isLoading = false;
            });
          this.isLoading = false;
        } else {
          this.productService.getProductsAll().subscribe((products) => {
            this.products = products;
            this.fixOrder();

            this.slides = this.products.map((product) => {
              const displayValue = false;

              let price = "";
              let currencySymbol = "";

              if (displayValue) {
                const currencyCode = this.currencies.find(
                  (x) => x.id === product.moeda
                ).Code;
                const currencySymbols = {
                  BRL: "R$",
                  USD: "US$",
                  EUR: "€",
                };
                currencySymbol = currencySymbols[currencyCode] || currencyCode;
                price = product.price.toString();
              }

              return {
                image:
                  product.photos && product.photos[0] ? product.photos[0] : "",
                name: product.name,
                price: price,
                code: currencySymbol,
                productData: product,
              };
            });

            this.isLoading = false;
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // Add order number
  fixOrder() {
    for (let i = 0; i < this.products.length; i++) {
      if (
        this.products[i] &&
        (this.products[i]["ordem"] === undefined ||
          this.products[i]["ordem"] === null)
      ) {
        this.products[i] = { ...this.products[i], ordem: 100 };
      }
    }
  }

  orderList(value) {
    this.sortByOrder = value;
  }

  // Search Input
  toggleSearchInput() {
    this.inputVisible = !this.inputVisible;
    if (this.inputVisible) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      });
    }
  }

  hideInput() {
    this.inputVisible = false;
  }

  // Filter Dropdown
  toggleSelect() {
    this.selectVisible = !this.selectVisible;

    if (this.selectVisible) {
      this.dropdownIcon.nativeElement.style.transform = "rotate(180deg)";
      setTimeout(() => {
        this.select.nativeElement.focus();
      });
    } else {
      this.dropdownIcon.nativeElement.style.transform = "rotate(0deg)";
    }
  }

  @HostListener("document:mousedown", ["$event"])
  onDocumentMouseDown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInsideSelect = this.select.nativeElement.contains(target);
    const clickedInsideLabel = this.label.nativeElement.contains(target);
    if (!clickedInsideSelect && !clickedInsideLabel) {
      this.selectVisible = false;
      this.dropdownIcon.nativeElement.style.transform = "rotate(0deg)";
    }
  }

  // Cart Dropdown
  // Redirect to Cart when using mobile device
  // @HostListener('window:resize', ['$event'])
  // onWindowResize(event){
  //   this.handleMobileView();
  // }

  // handleMobileView(){
  //   const isMobileView = window.innerWidth <= 480;
  //   if(isMobileView){
  //     this.router.navigate(['/cart'])
  //   }
  // }
}
