import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-nav-list',
  templateUrl: './info-nav-list.component.html',
  styleUrls: ['./info-nav-list.component.css']
})
export class InfoNavListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
