import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { CartItem } from "src/app/shared/classes/cart-item";
import { CartService } from "src/app/shared/services/cart.service";

@Component({
  selector: "app-search-and-cart",
  templateUrl: "./search-and-cart.component.html",
  styleUrls: ["./search-and-cart.component.css"],
})
export class SearchAndCartComponent implements OnInit {
  inputVisible: boolean = false;
  @ViewChild("searchInput") searchInput: any;
  cartItems: CartItem[];

  constructor(private router: Router, private cartService: CartService) {}

  ngOnInit(): void {
    this.cartService.cartItemsSubject.subscribe((cartItems) => {
      this.cartItems = cartItems;
    });
  }

  // Search Input
  toggleSearchInput() {
    this.inputVisible = !this.inputVisible;
    if (this.inputVisible) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      });
    }
  }

  hideInput() {
    this.inputVisible = false;
  }

  // Cart Dropdown
  // Redirect to Cart when using mobile device
  // @HostListener('window:resize', ['$event'])
  // onWindowResize(event){
  //   this.handleMobileView();
  // }

  handleMobileView() {
    const isMobileView = window.innerWidth <= 480;
    if (isMobileView) {
      this.router.navigate(["/cart"]);
    }
  }
}
