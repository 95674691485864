<app-wrapper-loading-spinner [isLoading]="isLoading"></app-wrapper-loading-spinner>

<div class="hero-s">
  <ngx-slick-carousel class="hero-carousel" [config]="carouselConfig">
    <div class="hero-carousel-slide" *ngFor="let slide of slides" ngxSlickItem (click)="redirectTo(slide.buttonLink)">
      <div class="hero-carousel-wrapper">
        <img src="{{ slide.image }}" alt="">
        <div class="container" [ngClass]="getContainerClass(slide)">
          <div class="hero-left-content">
            <div class="hero-left-content-wrapper">
              <h1 *ngIf="slide.mainTxt">{{ slide.mainTxt }}<span>{{ slide.highlight }}</span></h1>
              <div class="hero-btns" *ngIf="slide.buttonLink && slide.buttonTxt">
                <button
                  class="btn btn-default custom-btn"
                  routerLink="{{ slide.buttonLink }}"
                  [style.background-color]="slide.buttonColor"
                >
                  <a href="{{ slide.buttonLink }}" [style.color]="slide.fontColor">
                    {{ slide.buttonTxt }}
                  </a>
                </button>
              </div>
            </div>
          </div>
          <div class="hero-right-content" *ngIf="slide.hasLogo">
            <div class="logo-wrapper">
              <img src="{{ slide.imageRight }}" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>