<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h2>{{ product.name }}</h2>
        </div>
      </div>
      <div class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/home/one']">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="['/home/collection']">collection</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{ product.name }}</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-sm-12 col-xs-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="filter-main-btn mb-2">
                  <span class="filter-btn" (click)="mobileSidebar()"><i class="fa fa-bars" aria-hidden="true"></i> sidebar</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <ngx-slick-carousel class="product-slick" #slickModal="slick-carousel" [config]="slideConfig">
                  <div ngxSlickItem *ngFor="let image of product.photos">
                    <div>
                      <img [src]="image" alt="" class="img-fluid">
                    </div>
                    </div>
                </ngx-slick-carousel>
                <div class="row">
                  <div class="col-12 p-0">
                    <ngx-slick-carousel class="slider-nav" #slickModal="slick-carousel" [config]="slideNavConfig">
                      <div ngxSlickItem *ngFor="let image of product.photos">
                        <div>
                          <img [src]="image" alt="" class="img-fluid">
                        </div>
                        </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 rtl-text">
                <div class="product-right">
                  <h2>{{product.name}}</h2>
                  <h4 *ngIf="!productsService?.catalogMode"><del>{{product.salePrice | currency:productsService?.currency:'symbol'}}</del><span>{{product.discount}}% off</span></h4>
                  <h3 *ngIf="!productsService?.catalogMode">{{product.price | currency:productsService?.currency:'symbol'}}</h3>
                  <ul class="color-variant" *ngIf="!productsService?.catalogMode">
                    <li [class]="variant.color" (click)="slickModal.slickGoTo(i)" *ngFor="let variant of product.variants let i = index"></li>
                  </ul>
                  <div class="pro_inventory" *ngIf="product.stock != 0 && product.stock <= 5 && !productsService?.catalogMode">
                    <p class="active">
                      Corra! Só temos {{product.stock}} em estoque.
                    </p>
                    <div class="inventory-scroll nostripes active">
                      <span style="width: 95%;"></span>
                    </div>
                  </div>
                  <div class="product-description border-product" *ngIf="!productsService?.catalogMode">
                    <h6 class="product-title size-text">Selecionar Tamanho
                        <span><a href="" data-toggle="modal" data-target="#sizemodal">size chart</a></span>
                    </h6>
                    <div class="size-box">
                      <ul>
                        <li [ngClass]="{'active': selectedSize == size}" *ngFor="let size of product.size">
                          <a [routerLink]="" (click)="changeSizeVariant(size)">{{size}}</a>
                        </li>
                      </ul>
                    </div>
                    <h5 class="avalibility" *ngIf="counter <= product.stock"><span>Em estoque</span></h5>
                    <h5 class="avalibility" *ngIf="counter > product.stock"><span>Sem estoque</span></h5>
                    <h6 class="product-title">quantidade</h6>
                    <div class="qty-box">
                      <div class="input-group">
                        <span class="input-group-prepend">
                            <button type="button" (click)="decrement()" class="btn quantity-left-minus" data-type="minus" data-field="">
                               <i class="ti-angle-left"></i>
                           </button>
                       </span>
                        <input type="text" disabled  name="quantity" class="form-control input-number" value="{{counter}}">
                        <span class="input-group-prepend">
                            <button type="button" (click)="increment()" class="btn quantity-right-plus" data-type="plus" data-field="">
                                <i class="ti-angle-right"></i>
                            </button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="product-buttons" *ngIf="!productsService?.catalogMode">
                    <a [routerLink]="" (click)="addToCart(product, counter)" class="btn btn-solid" [class.disabled]="counter > product.stock">Adicionar ao carrinho</a>
                    <a [routerLink]="" (click)="buyNow(product, counter)" class="btn btn-solid" [class.disabled]="counter > product.stock">Comprar Agora</a>
                  </div>
                  <div class="border-product">
                    <h6 class="product-title">detalhes do produto</h6>
                    <p>{{product.shortDetails}}</p>
                  </div>
                  <div class="border-product">
                    <h6 class="product-title">share it</h6>
                    <div class="product-icon">
                      <ul class="product-social">
                        <li><a href=""><i class="fa fa-facebook"></i></a></li>
                        <li><a href="https://plus.google.com/discover"><i class="fa fa-google-plus"></i></a></li>
                        <li><a href="https://twitter.com/"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"></i></a></li>
                      </ul>
                      <form class="d-inline-block" *ngIf="!productsService?.catalogMode">
                        <button class="wishlist-btn" (click)="addToWishlist(product)"><i class="fa fa-heart"></i><span class="title-font">Add To WishList</span></button>
                      </form>
                    </div>
                  </div>
                  <div class="border-product" *ngIf="!productsService?.catalogMode">
                    <h6 class="product-title">Time Reminder</h6>
                    <div class="timer">
                      <p id="timer">
                        <span>25
            <span class="padding-l">:</span>
                        <span class="timer-cal">Days</span>
                        </span>
                        <span>22
            <span class="padding-l">:</span>
                        <span class="timer-cal">Hrs</span>
                        </span>
                        <span>13
            <span class="padding-l">:</span>
                        <span class="timer-cal">Min</span>
                        </span>
                        <span>57
            <span class="timer-cal">Sec</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="tab-product m-0">
            <div class="row">
              <div class="col-sm-12 col-lg-12">
                <ul class="nav nav-tabs nav-material" id="top-tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="top-home-tab" data-toggle="tab" href="#top-home" role="tab" aria-selected="true">
                <i class="icofont icofont-ui-home"></i>Descrição</a>
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-top-tab" data-toggle="tab" href="#top-profile" role="tab" aria-selected="false"><i class="icofont icofont-man-in-glasses"></i>Detalhes</a>
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="contact-top-tab" data-toggle="tab" href="#top-contact" role="tab" aria-selected="false">
                    <i class="icofont icofont-contacts"></i>Video</a>
                    <div class="material-border"></div>
                  </li>
                 
                </ul>
                <div class="tab-content nav-material" id="top-tabContent">
                  <div class="tab-pane fade show active" id="top-home" role="tabpanel" aria-labelledby="top-home-tab">
                    <p>{{product.description}}</p>
                  </div>
                  <div class="tab-pane fade" id="top-profile" role="tabpanel" aria-labelledby="profile-top-tab">
                    <p>{{product.shortDetails}}</p>
                    <div class="single-product-tables">
                      <table>
                        <tbody>
                          <tr>
                            <td>Febric</td>
                            <td>Chiffon</td>
                          </tr>
                          <tr>
                            <td>Color</td>
                            <td>Red</td>
                          </tr>
                          <tr>
                            <td>Material</td>
                            <td>Crepe printed</td>
                          </tr>
                        </tbody>
                      </table>
                      <table>
                        <tbody>
                          <tr>
                            <td>Length</td>
                            <td>50 Inches</td>
                          </tr>
                          <tr>
                            <td>Size</td>
                            <td>S, M, L .XXL</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">
                    <div class="mt-3 text-center">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/BUWzX78Ye_8" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="top-review" role="tabpanel" aria-labelledby="review-top-tab">
                    <form [formGroup]="form" class="theme-form">
                      <div class="form-row">
                        <div class="col-md-12 ">
                          <div class="media">
                            <label>Rating</label>
                            <div class="media-body ml-3">
                              <div class="rating three-star">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label for="name">Name</label>
                          <input type="text" class="form-control" id="name" placeholder="Enter Your name" required>
                        </div>
                        <div class="col-md-6">
                          <label for="email">Email</label>
                          <input type="text" class="form-control" id="email" placeholder="Email" required>
                        </div>
                        <div class="col-md-12">
                          <label for="review">Review Title</label>
                          <input type="text" class="form-control" id="review" placeholder="Enter your Review Subjects" required>
                        </div>
                        <div class="col-md-12">
                          <label for="review">Review Title</label>
                          <textarea class="form-control" placeholder="Wrire Your Testimonial Here" id="exampleFormControlTextarea1" rows="6"></textarea>
                        </div>
                        <div class="col-md-12">
                          <button class="btn btn-solid" type="submit">Submit YOur Review</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-sm-3 collection-filter">
          <product-details-sidebar></product-details-sidebar>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section ends -->
