import { Component, OnInit } from '@angular/core';
import { Product } from '../../../shared/classes/product';
import { CartItem } from '../../../shared/classes/cart-item';
import { ProductsService } from '../../../shared/services/products.service';
import { CartService } from '../../../shared/services/cart.service';
import { Observable, of } from 'rxjs';
import { CurrencyService as CurrencyService } from 'src/app/shared/service/currency.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  isLoading: boolean = false;
  public cartItems: Observable<CartItem[]> = of([]);
  public shoppingCartItems: CartItem[] = [];

  constructor(private productsService: ProductsService,
    private currencieService: CurrencyService,
    private cartService: CartService) { }

  ngOnInit() {
    this.isLoading = true;
    this.cartItems = this.cartService.getItems();
    this.cartItems.subscribe(shoppingCartItems => {
      this.shoppingCartItems = shoppingCartItems
    });
    this.isLoading = false;
  }

  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    this.cartService.updateCartQuantity(product, quantity);
  }

  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    this.cartService.updateCartQuantity(product, quantity);
  }

  // Get Total
  public getTotal(): Observable<number> {
    return this.cartService.getTotalAmount();
  }

  public getTotalEmReal(): Observable<number> {
    return this.cartService.getTotalEmRealAmount();
  }
  
  // Remove cart items
  public removeItem(item: CartItem) {
    this.isLoading = true;
    this.cartService.removeFromCart(item);
    this.isLoading = false;
  }

  isLink(possibleUrl) {
    let url;

    try {
      url = new URL(possibleUrl);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }
}
