<app-wrapper-loading-spinner [isLoading]="isLoading"></app-wrapper-loading-spinner>
<div class="main-products-s">
 <div class="container">
  <div class="top-main-products-s">
    <div class="top-txt title-s">
      <h1>Para <span>você!</span></h1>
      <div class="mobile-img">
        <img src="assets/images/image-globe.png" alt="">
      </div>
      <div class="subtitle">
        <p>
          Programas para ampliar seus horizontes, evoluir e transformar a sua história.
        </p>
      </div>
    </div>
    <div class="top-img">
      <img src="assets/images/image-globe.png" alt="">
    </div>
  </div>

  <!-- Main Content -->
  <div class="products-banners-s">
    <div class="banner-wrapper">
      <!-- Mini Banners -->
      <app-minibanner *ngFor="let banner of allBanners"
        [categoryName]="banner.name"
        [categoryHighlight]="banner.highlight"
        [categoryImage]="banner.imageUrl"
        [categoryPrice]="banner.value"
        [categoryDescription]="banner.description"
        [categoryId]="banner.categoryId"
        [categoryCode]="banner.code"
      >
      </app-minibanner>
    </div>
  </div>
 </div>
</div>
