<div class="social-media-s">
  <div class="container">
    <h4>Vessel nas redes sociais:</h4>
    <div class="links">
      <div class="media">
        <a href="https://lp.grupovessel.com/lp-grupo-vessel-arvore-de-links-facebook" target="_blank">
          <img src="assets/images/face.png" alt="Facebook">
        </a>
      </div>
      <div class="media">
        <a href="https://lp.grupovessel.com/lp-grupo-vessel-arvore-de-links-instagram" target="_blank">
          <img src="assets/images/insta.png" alt="Instagram">
        </a>
      </div>
      <div class="media">
        <a href="https://wa.me/554135289080" target="_blank">
          <img src="assets/images/whats.png" alt="Whatsapp">
        </a>
      </div>
    </div>
  </div>
</div>