<aside class="category-aside">
  <div class="aside-title" (click)="toggleAsideDropdown()">
    <h4 class="title">Categorias</h4>
    <span #asideDropdown><i class="fa fa-angle-up"></i></span>
  </div>
  <div class="aside-list" [ngClass]="{ show: asideDropdownVisible }">
    <ul>
      <li *ngFor="let category of categories">
        <a (click)="navigateToCategory(category.id)">
          {{ category.Nome }}
        </a>
      </li>
    </ul>
  </div>
</aside>
