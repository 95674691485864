<div class="wrapper-loader">
  <div class="overlay-loader" *ngIf="isLoading">
    <div class="spinner-wrapper">
      <app-spinner></app-spinner>
    </div>
  </div>

  <div class="loaded-content" [class.blurred]="isLoading">
    <ng-content></ng-content>
  </div>
</div>