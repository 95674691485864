import { Component, OnInit } from "@angular/core";
import { CartItem } from "src/app/shared/classes/cart-item";
import { CartService } from "src/app/shared/services/cart.service";

@Component({
  selector: "app-cart-dropdown",
  templateUrl: "./cart-dropdown.component.html",
  styleUrls: ["./cart-dropdown.component.css"],
})
export class CartDropdownComponent implements OnInit {
  isLoading: boolean = false;
  cartItem = [];
  subTotal: string;

  constructor(private cartService: CartService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.cartService.cartItemsSubject.subscribe((cartItems) =>
      this.cartItemsUpdated(cartItems)
    );
  }

  cartItemsUpdated(cartItems: CartItem[]) {
    this.cartItem = cartItems.map((item) => {
      return {
        image: item.product.photos[0],
        name: item.product.name,
        price: item.product.price.toString(),
        code: item.product.moedaCode,
        parcela: "1",
        cartItem: item,
      };
    });
    this.cartService.getTotalEmRealAmount().subscribe((total) => {
      this.subTotal = total.toString();
    });
    this.isLoading = false;
  }

  EraseProduct(cartItem: CartItem) {
    this.cartService.removeFromCart(cartItem);
  }
}
