import { Component, OnInit } from '@angular/core';
import { CategoriaService } from 'src/app/shared/services/categoria.service';
import { MinibannerService } from 'src/app/shared/services/minibanner.service';

@Component({
  selector: 'app-main-products',
  templateUrl: './main-products.component.html',
  styleUrls: ['./main-products.component.css']
})
export class MainProductsComponent implements OnInit {
  allBanners: any[] = [];
  isLoading: boolean = false;

  constructor(
    private categoryService: CategoriaService,
    private bannerService: MinibannerService
  ) {

  }

  ngOnInit(){
    this.isLoading = true;
    this.loadCategories();
  }

  loadCategories(){
    this.bannerService.getBanners().subscribe((allBanners) => {
      this.allBanners = allBanners
        .map((banner) => {
          const nameWords = banner.name ? banner.name.split(' ') : [];
          return{
            ...banner,
            name: nameWords[0],
            highlight: nameWords.splice(1).join(' ')
          }
        })
      this.isLoading = false;
    })
  }

  
}
