<app-wrapper-loading-spinner [isLoading]="isLoading"></app-wrapper-loading-spinner>

<div class="cart-p">
  <!-- Breadcrumb -->
  <div class="breadcrumb-s">
    <div class="container">
      <div class="breadcrumb">
        <ul>
          <li><a routerLink="/">Início</a></li>
          <li>/</li>
          <li><a href="">Carrinho</a></li>
        </ul>
      </div>
      <app-search-and-cart></app-search-and-cart>
    </div>
  </div>

  <!-- Main Section -->
  <main class="cart-s">
    <div class="container">
      <div class="table-wrapper">

        <!-- Top Table -->
        <section class="cart-table">
          <h4 class="title">Produtos</h4>
          <!-- Table Body -->
          <div class="cart-table-body">
            <!-- Empty Cart -->
            <div class="empty-cart" *ngIf="!shoppingCartItems.length">
              <img src="assets/images/icon-empty-cart.png" class="empty-cart-img" />
              <p>Seu carrinho está vazio!</p>
            </div>
            <!-- Produt Row -->
            <ng-container *ngIf="shoppingCartItems">
              <div class="cart-table-content" *ngIf="shoppingCartItems.length">
                <div class="product-table-row" *ngFor="let item of shoppingCartItems">
                  <!-- Image -->
                  <div class="table-img" [routerLink]="['/home/left-sidebar/product', item.product.id]">
                    <img [src]="item.product.photos[0]" alt="">
                  </div>
                  <!-- Details -->
                  <div class="table-details">
                    <a class="table-product-name" [routerLink]="['/home/left-sidebar/product', item.product.id]">
                      {{ item.product.name }}
                    </a>
                    <div class="table-product-details" *ngIf="item.product.camposCustomizados">
                      <p class="table-product-details-txt title">Detalhes:</p>
                      <ng-container
                        class="table-product-fields"
                        *ngFor="let campo of item.product.camposCustomizados | keyvalue"
                      >
                        <p class="table-product-details-txt">
                          <ng-container *ngIf="!isLink(campo.value)">
                            <span class="key">{{ campo.key | campoNome }}: </span>
                            <span class="value">{{ campo.value }}</span>
                          </ng-container>
                          <ng-container *ngIf="isLink(campo.value)">
                            <span class="key">{{ campo.key | campoNome }}: </span>
                            <a class="value" [href]="campo.value" target="_blank">Arquivo</a>
                          </ng-container>
                        </p>
                      </ng-container>
                    </div>
                    <button class="btn-link" (click)="removeItem(item)" [routerLink]="">Excluir</button>
                  </div>
                  <!-- Quantity -->
                  <div class="table-quantity">
                    <div class="input-wrapper">
                      <label for="quantity" class="form-label">Quantidade:</label>
                      <input type="number" name="quantity" [(ngModel)]="item.quantity" disabled>
                    </div>
                  </div>
                  <!-- Total -->
                  <div class="table-value">
                    <p>
                      {{ item.product.price * item.quantity | currency: item.product.moedaCode }}
                    </p>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </section>
        <!-- Bottom Table -->
        <section class="cart-table-bottom">
          <p>
            Total ({{ shoppingCartItems.length }} itens): 
            <span class="total">{{ getTotalEmReal() | async | currency: "BRL" }}</span>
          </p>
          <div class="redirections">
            <button class="btn btn-primary" [routerLink]="['/product-category-list']">
              Continuar comprando
            </button>
            <button class="btn btn-secondary close-order" [routerLink]="['/checkout']">
              Fechar pedido
            </button>
          </div>
        </section>
      </div>


    </div>
  </main>
</div>