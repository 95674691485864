import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  private loggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();

  constructor() { }

  setLoggedIn(value: boolean){
    this.loggedInSubject.next(value);
  }

  isLoggedIn(){
    return this.loggedInSubject.getValue();
  }

  handleLogout() {
    this.setLoggedIn(false);
  }
}
