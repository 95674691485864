import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { FrontPageService } from 'src/app/shared/service/front-page.service';
import { WINDOW } from 'src/app/shared/services/windows.service';

@Component({
  selector: 'app-carousel-section',
  templateUrl: './carousel-section.component.html',
  styleUrls: ['./carousel-section.component.css']
})
export class CarouselSectionComponent implements OnInit {
  isLoading: boolean = false;
  slides: any[];
  subscriptions;
  carouselConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  constructor(
    private storage: AngularFireStorage,
    private frontpageService: FrontPageService
  ) { }

  ngOnInit(){
    this.isLoading = true;
    this.getCarousel();
  }

  getCarousel() {
    this.frontpageService.getProdutoDestaque().subscribe(response => {
      let filter = response.filter((item) => {
        if(item['active']){
          return true;
        } else {
          return false;
        }
      })

      this.slides = filter.map((carouselItem) => {
        return {
          ...carouselItem,
          image: carouselItem['imgSrc'],
        }
      })

      // Ensure minimun of 4 cards
      while(this.slides.length < 4){
        for (let i = 0; i < this.slides.length && this.slides.length < 4; i++) {
          // clone card of index i
          const clonedCard = { ...this.slides[i] }; 
          this.slides.push(clonedCard);
        }
      }
      // console.log('SLIDES', this.slides)

    });

    this.isLoading = false;
  }


  redirectTo(link){
    window.location.href = link;
  }

}
