<!-- price filter start here -->
<div class="collection-collapse-block border-0 open">
    <h3 class="collapse-block-title">Preço</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <div class="mt-5">
                 <range-slider [min]="min" [max]="max" [step]="1" [(ngModel)]="range" (onRangeChange)="priceChanged($event)" [highlightClass]="'stephighlightClass'" [barClass]="'stepBarClass'" [sliderClass]="'stepSliderClass'">    
                 </range-slider>
            </div>
        </div>
    </div>
</div>