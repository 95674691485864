<div class="dropdown">
  <div class="container">
    <ul>
      <li *ngFor="let category of categories">
        <a (click)="navigateToCategory(category.id)">
          {{ category.Nome }}
        </a>
      </li>
    </ul>
  </div>
</div>