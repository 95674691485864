import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  productsUrl = 'https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-products/';

  constructor(private db: AngularFirestore, private http: HttpClient) { }

  getCategorias(){
    return this.getCategoriasReq();
  }
  getCategoriasReq() {
    const url = `${this.productsUrl}/categories`;
    const headers = {'content-type' : 'application/json'};
    return this.http.get<any>(url, {'headers': headers});
  }
}

export interface CategoriesResponse {

}