import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MinibannerService } from 'src/app/shared/services/minibanner.service';

@Component({
  selector: 'app-minibanner',
  templateUrl: './minibanner.component.html',
  styleUrls: ['./minibanner.component.css']
})
export class MinibannerComponent implements OnInit {
  allCategories: any[] = [];
  @Input() categoryName: string;
  @Input() categoryHighlight: string;
  @Input() categoryImage: string;
  @Input() categoryPrice: string;
  @Input() categoryDescription: string;
  @Input() categoryId: string;
  @Input() categoryCode: string;


  constructor(
    private router: Router,
    private bannerService: MinibannerService

  ) { }

  ngOnInit(){
    this.loadCategories()
  }

  loadCategories(){
    this.bannerService.getBanners().subscribe((allcategories) => {
      this.allCategories = allcategories;

    }, (error) => {
      console.log(error);
    })
  }

  onNavigateToCategory(categoryId){
    this.router.navigate(['/product-category-list', categoryId]).then(() => {
      window.location.reload();
    });
  }

}
