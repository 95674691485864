import { Injectable } from "@angular/core";
import { Http } from "@angular/http";

@Injectable({
  providedIn: "root",
})
export class CepService {
  /* protected basePath = 'https://h-apigateway.conectagov.estaleiro.serpro.gov.br/api-cep/v1/consulta/cep/'; */
  protected basePath = "https://viacep.com.br/ws/";

  resultado:any;
  cep= {
    cep:"",
    logradouro:"",
    complemento:"",
    bairro:"",
    cidade:"",
    estado:"",
  }

  constructor(private http: Http) {}

  consultaCEP(cep: string) {
    return this.http
      .get(this.basePath + cep + "/json/")
      .toPromise()
      .then((resp) => {
        return resp.json()
      });
  }

  /* consultaCEP(cep: string) {
    console.log(this.basePath + cep);
    return this.http
      .get(this.basePath + cep)
      .toPromise()
      .then((resp) => {
        console.log(resp);
      });
  } */
}
