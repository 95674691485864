import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { IMaskModule } from "angular-imask";
import { BarRatingModule } from "ngx-bar-rating";
import { NgxImgZoomModule } from "ngx-img-zoom";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxPayPalModule } from "ngx-paypal";
import { RangeSliderModule } from "ngx-rangeslider-component";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SharedModule } from "../shared/shared.module";
import { BlogComponent } from "./home/blog/blog.component";
import { CollectionBannerComponent } from "./home/collection-banner/collection-banner.component";
// Home-one components
import { HomeComponent } from "./home/home.component";
import { InstagramComponent } from "./home/instagram/instagram.component";
import { LogoComponent } from "./home/logo/logo.component";
import { ParallaxBannerComponent } from "./home/parallax-banner/parallax-banner.component";
import { ProductSliderComponent } from "./home/product-slider/product-slider.component";
import { ExperienceSliderComponent } from "./home/experience-slider/experience-slider.component";
import { ProductTabComponent } from "./home/product-tab/product-tab.component";
import { ServicesComponent } from "./home/services/services.component";
import { SliderComponent } from "./home/slider/slider.component";
import { CartComponent } from "./product/cart/cart.component";
import { CheckoutComponent } from "./product/checkout/checkout.component";
import { BoletoConfirmationComponent } from "./product/checkout/boleto-confirmation.component";
import { CollectionLeftSidebarComponent } from "./product/collection/collection-left-sidebar/collection-left-sidebar.component";
import { CollectionNoSidebarComponent } from "./product/collection/collection-no-sidebar/collection-no-sidebar.component";
import { CollectionRightSidebarComponent } from "./product/collection/collection-right-sidebar/collection-right-sidebar.component";
import { BrandComponent } from "./product/collection/filter/brand/brand.component";
import { ColorComponent } from "./product/collection/filter/color/color.component";
import { PriceComponent } from "./product/collection/filter/price/price.component";
import { ProductBoxHoverComponent } from "./product/product-box-hover/product-box-hover.component";
import { ProductBoxMetroComponent } from "./product/product-box-metro/product-box-metro.component";
import { ProductBoxVerticalComponent } from "./product/product-box-vertical/product-box-vertical.component";
import { ProductBoxComponent } from "./product/product-box/product-box.component";
import { ProductCompareComponent } from "./product/product-compare/product-compare.component";
import { ProductAccordianComponent } from "./product/product-details/product-accordian/product-accordian.component";
import { ProductColLeftComponent } from "./product/product-details/product-col-left/product-col-left.component";
import { ProductColRightComponent } from "./product/product-details/product-col-right/product-col-right.component";
import { ProductColumnComponent } from "./product/product-details/product-column/product-column.component";
import { ProductLeftImageComponent } from "./product/product-details/product-left-image/product-left-image.component";
import { ProductLeftSidebarComponent } from "./product/product-details/product-left-sidebar/product-left-sidebar.component";
import { ProductNoSidebarComponent } from "./product/product-details/product-no-sidebar/product-no-sidebar.component";
import { ProductRightImageComponent } from "./product/product-details/product-right-image/product-right-image.component";
import { ProductRightSidebarComponent } from "./product/product-details/product-right-sidebar/product-right-sidebar.component";
import { ProductVerticalTabComponent } from "./product/product-details/product-vertical-tab/product-vertical-tab.component";
import { RelatedProductsComponent } from "./product/product-details/related-products/related-products.component";
import { SidebarComponent } from "./product/product-details/sidebar/sidebar.component";
// Products Components
import { ProductComponent } from "./product/product.component";
import { SearchComponent } from "./product/search/search.component";
import { SuccessComponent } from "./product/success/success.component";
import { AgeVerificationComponent } from "./product/widgets/age-verification/age-verification.component";
import { CategoriesComponent } from "./product/widgets/categories/categories.component";
import { ExitPopupComponent } from "./product/widgets/exit-popup/exit-popup.component";
import { ModalCartComponent } from "./product/widgets/modal-cart/modal-cart.component";
import { NewProductComponent } from "./product/widgets/new-product/new-product.component";
import { NewsletterComponent } from "./product/widgets/newsletter/newsletter.component";
import { QuickViewComponent } from "./product/widgets/quick-view/quick-view.component";
import { WishlistComponent } from "./product/wishlist/wishlist.component";
import { SafePipe } from "./safe.pipe";
import { ShopRoutingModule } from "./shop-routing.module";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MaterialFileInputModule } from "ngx-material-file-input";
import { MatIconModule } from "@angular/material/icon";
import { HeroComponent } from "./home/hero/hero.component";
import { MainProductsComponent } from "./home/main-products/main-products.component";
import { MinibannerComponent } from "./home/minibanner/minibanner.component";
import { CarouselSectionComponent } from "./home/carousel-section/carousel-section.component";
import { SocialMediaSectionComponent } from "./home/social-media-section/social-media-section.component";
import { ProductCategoryListComponent } from "./product/product-category-list/product-category-list.component";
import { ProductLeftAsideComponent } from "./product/product-details/product-left-aside/product-left-aside.component";
import { CartDropdownComponent } from "./product/widgets/cart-dropdown/cart-dropdown.component";
import { ProductDescriptionComponent } from "./product/product-description/product-description.component";
import { NewProductsAsideComponent } from "./product/product-details/new-products-aside/new-products-aside.component";
import { BreadcrumbComponent } from "./product/widgets/breadcrumb/breadcrumb.component";
import { SearchAndCartComponent } from "./product/widgets/search-and-cart/search-and-cart.component";
import { WrapperLoadingSpinnerComponent } from "../shared/loading-spinner/wrapper-loading-spinner/wrapper-loading-spinner.component";

import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  exports: [ExitPopupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ShopRoutingModule,
    SharedModule,
    SlickCarouselModule,
    BarRatingModule,
    RangeSliderModule,
    InfiniteScrollModule,
    NgxPayPalModule,
    NgxImgZoomModule,
    IMaskModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatIconModule,
    MaterialFileInputModule,
    MatDialogModule,
  ],
  declarations: [
    // Home one
    HomeComponent,
    SliderComponent,
    CollectionBannerComponent,
    ProductSliderComponent,
    ExperienceSliderComponent,
    ParallaxBannerComponent,
    ProductTabComponent,
    ServicesComponent,
    BlogComponent,
    InstagramComponent,
    LogoComponent,
    SafePipe,

    // Product
    ProductComponent,
    ProductBoxComponent,
    ProductBoxHoverComponent,
    ProductBoxVerticalComponent,
    ProductBoxMetroComponent,
    CollectionLeftSidebarComponent,
    CollectionRightSidebarComponent,
    CollectionNoSidebarComponent,
    ColorComponent,
    BrandComponent,
    PriceComponent,
    ProductLeftSidebarComponent,
    ProductRightSidebarComponent,
    ProductNoSidebarComponent,
    ProductColLeftComponent,
    ProductColRightComponent,
    ProductColumnComponent,
    ProductAccordianComponent,
    ProductLeftImageComponent,
    ProductRightImageComponent,
    ProductVerticalTabComponent,
    RelatedProductsComponent,
    SidebarComponent,
    CategoriesComponent,
    QuickViewComponent,
    ModalCartComponent,
    NewProductComponent,
    SearchComponent,
    ProductCompareComponent,
    WishlistComponent,
    CartComponent,
    CheckoutComponent,
    BoletoConfirmationComponent,
    SuccessComponent,
    ExitPopupComponent,
    AgeVerificationComponent,
    NewsletterComponent,
    HeroComponent,
    MainProductsComponent,
    MinibannerComponent,
    CarouselSectionComponent,
    SocialMediaSectionComponent,
    ProductCategoryListComponent,
    ProductLeftAsideComponent,
    CartDropdownComponent,
    ProductDescriptionComponent,
    NewProductsAsideComponent,
    BreadcrumbComponent,
    SearchAndCartComponent,
    WrapperLoadingSpinnerComponent,
  ],
  entryComponents: [BoletoConfirmationComponent],
})
export class ShopModule {}
