import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GetnetService {
  constructor(private httpClient: HttpClient) {}

  getBearer() {
    //Prod 
    // "Basic ZTEwYzM1MjctYzIxNC00NTcxLWI2NTQtZmRiMDk2NjQ1ZjI4OjM0NDc0Yjk5LWE1ZWYtNDdiMS04ODcxLWY5YjI4ZjMzMmU4Yw==",
    const headers = {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
       "Basic ZTEwYzM1MjctYzIxNC00NTcxLWI2NTQtZmRiMDk2NjQ1ZjI4OjM0NDc0Yjk5LWE1ZWYtNDdiMS04ODcxLWY5YjI4ZjMzMmU4Yw==",
      // 'Basic NzNhOTNhMWMtMGQxYy00YTEzLTkxMDUtZWE4N2FmZDU0MDliOmRmOGEzZTQxLTJhNjMtNDE4MC04YWEzLWE2M2U2YzRjNWFhZQ=='  
      
    };
       //prod
      
    return this.httpClient.post(
     // 'https://api-sandbox.getnet.com.br/auth/oauth/v2/token',
     " https://api.getnet.com.br/auth/oauth/v2/token",
      `scope=oob&grant_type=client_credentials`,

      { headers }
    );
  }
}
