import { Component, OnInit } from "@angular/core";
import { Product } from "../../shared/classes/product";
import { ProductsService } from "../../shared/services/products.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public products: Product[] = [];
  public productsPhysical: Product[] = [];
  isLoading: boolean = false;

  constructor(private productsService: ProductsService) {}

  ngOnInit() {
    this.isLoading = true;

    this.productsService.getProducts().subscribe((product) => {
      this.products = this.sortProducts(product);
      this.isLoading = false;
      // this.products = product;
    });
    this.productsService.getProductsPhisical().subscribe((product) => {
      this.productsPhysical = this.sortProducts(product);
      this.isLoading = false;
      // this.productsPhysical = product;
    });
  }

  sortProducts(products) {
    return products.sort(function (a, b) {
      var dateA = new Date(a.recent_data);
      var dateB = new Date(b.recent_data);
      if (!b.recent_data) {
        var dateB = new Date("Fri, 13 Mar 2020 12:20:14 GMT");
      }
      if (!a.recent_data) {
        var dateA = new Date("Fri, 13 Mar 2020 12:20:14 GMT");
      }
      return dateB.valueOf() - dateA.valueOf();
    });
  }
}
