<!-- side-bar single product slider start -->
<div class="theme-card">
  <h5 class="title-border">Novos Produtos</h5>
  <ngx-slick-carousel class="offer-slider" #slickModal="slick-carousel">
    <div ngxSlickItem *ngFor="let i of index">
      <div class="media" *ngFor="let product of products3[i]">
        <ng-container *ngIf="product.status == 'true'">
          <a [routerLink]="['/home/left-sidebar/product', product.id]"
            ><img class="img-fluid" [src]="product.photos[0]" alt=""
          /></a>
          <div class="media-body align-self-center">
            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
            <a [routerLink]="['/home/left-sidebar/product', product.id]"
              ><h6>{{ product.name }}</h6></a
            >
            <h4 *ngIf="!productsService?.catalogMode">
              {{ product.price | currency: product["moedaCode"] }}
            </h4>
          </div>
        </ng-container>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
<!-- side-bar single product slider end -->
