<!-- product section start -->
<section class="section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-12 product-related">
                <h2>related products</h2>
            </div>
        </div>
        <div class="row search-product">
            <div class="col-xl-2 col-md-4 col-sm-6"  *ngFor="let product of products | slice:0:6">
                <div class="product-box">
                    <app-product [product]="product"></app-product>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- product section end -->
<app-quick-view></app-quick-view>