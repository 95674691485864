import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

interface IBanner {
  name: string,
  imageUrl: string,
  value: number,
  code: string,
  description: string
  categoryId: string,
}

@Injectable({
  providedIn: 'root'
})

export class MinibannerService {
  headers = {'content-type' : 'application/json'};
  menuUrl = 'https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-menu/';

  constructor(
    private http: HttpClient
  ) { }

  getBanners(){
    let url: string = this.menuUrl + "category-banners";

    return this.http.get<IBanner[]>(url, {'headers' : this.headers});
  }

}
