import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject, AsyncSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  public currencies: BehaviorSubject<ICurrency[]> = new BehaviorSubject([]);
  private _currencies = null;

  constructor(private db: AngularFirestore, private http: HttpClient) {
    this.getCurrenciesReq().subscribe((currencies) => {
      this.currencies.next(currencies);
    });
  }
  getCurrencies(): BehaviorSubject<ICurrency[]> {
    return this.currencies;
  }

  getCurrenciesReq() {
    const url = `https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-products/currencies`;
    const headers = { "content-type": "application/json" };
    return this.http.get<ICurrency[]>(url, { headers: headers });
  }
}

export interface ICurrency {
  id: string;
  Code: string;
  Nome: string;
  ValorEmDolar: number;
  ValorEmReal: number;
  deleted?: boolean;
}
