/**
 * API de Identidade Ailos - 1.0
 * API de Identidade Ailos.
 *
 * OpenAPI spec version: 1.0
 * Contact: arquitetura@ailos.coop.br
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';

import { Observable }                                        from 'rxjs/Observable';

import { ProblemDetails } from '../model/problemDetails';
import { RequisicaoLoginUrlDTO } from '../model/requisicaoLoginUrlDTO';

import { Configuration }                                     from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';


@Injectable()
export class AutenticacaoService {

    protected basePath = 'https://apiendpointhml.ailos.coop.br/ailos/identity/api/v1/autenticacao';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() configuration: Configuration) {
       
        if (configuration) {
            this.configuration = configuration;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Faz refresh do token JWT do Cooperado
     * 
     * @param code Token JWT de Autenticação do Cooperado
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indexAsync(code?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public indexAsync(code?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public indexAsync(code?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public indexAsync(code?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/token/refresh`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retorna um identificador para ser usado como parâmetro na chamada da tela de Login
     * 
     * @param parametros Contém os parâmetros necessários utilizados na montagem do identificador do desenvolvedor da Ailos
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public obterLoginUrl(parametros?: RequisicaoLoginUrlDTO, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public obterLoginUrl(parametros?: RequisicaoLoginUrlDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public obterLoginUrl(parametros?: RequisicaoLoginUrlDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public obterLoginUrl(parametros?: RequisicaoLoginUrlDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/login/obter/id`,
            parametros,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
