import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewCheckoutService {
  headers = {'content-type' : 'application/json'};
  asaasUrl: string = 'https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-sales/payment-link/';

  constructor(
    private http: HttpClient
  ) { }

  submitOrder(paymentData, orderData){
    let params = {
      paymentData: paymentData,
      orderData: orderData
    };

    const body = JSON.stringify(params);
    return this.http.post<any>(this.asaasUrl, body, { headers: this.headers });
  }
}
