import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AutenticacaoService } from './shared/services/autenticacao.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
	
   constructor(translate: TranslateService,private router: Router,private authenticationService :AutenticacaoService) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
      
      this.router.events.subscribe((event) => {
         if (event instanceof NavigationEnd) {
         }
   });
   }
 
  
    ngOnInit() { 
     $.getScript('assets/js/script.js');
    }
  
  }
  