import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ProductsService } from "src/app/shared/services/products.service";

@Component({
  selector: "app-new-products-aside",
  templateUrl: "./new-products-aside.component.html",
  styleUrls: ["./new-products-aside.component.css"],
})
export class NewProductsAsideComponent implements OnInit {
  asideDropdownVisible: boolean = true;
  destaqueProducts: any[];
  news: any[];

  @ViewChild("asideDropdown") asideDropdown!: ElementRef;

  constructor(private productsService: ProductsService) {}

  ngOnInit() {
    this.loadNewProducts();
    this.checkScreenWidth();
  }

  loadNewProducts() {
    this.productsService.getProductsAll().subscribe((products) => {
      let allProducts = [];
      let aux_products3 = [];

      products.forEach((item) => {
        if (item["status"] != "false" && item["destaque"] == "true") {
          allProducts.push(item);
        }
      });

      allProducts.sort(function (a, b) {
        var dateA =
          Date.parse(a.recent_data) ||
          Date.parse("Fri, 13 Mar 2020 12:20:14 GMT");
        var dateB =
          Date.parse(b.recent_data) ||
          Date.parse("Fri, 13 Mar 2020 12:20:14 GMT");
        return dateB - dateA;
      });

      this.news = allProducts.slice(0, 4);
    });
  }

  // Aside Dropdown
  checkScreenWidth() {
    if (window.innerWidth <= 768) {
      this.asideDropdownVisible = false;
    }
  }

  toggleAsideDropdown() {
    this.asideDropdownVisible = !this.asideDropdownVisible;
    this.rotateAsideIcon();
  }

  rotateAsideIcon() {
    const rotationValue = this.asideDropdownVisible ? "180deg" : "0deg";
    this.asideDropdown.nativeElement.querySelector(
      "i"
    ).style.transform = `rotate(${rotationValue})`;
  }
}
