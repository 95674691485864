import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  currentCategory: any[];

  @Input() name: string;
  @Input() highlight: string;
  @Input() product: string;
  @Input() categoryId: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
