// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyDaVoilyjOKtEPAwqo7PE4iUToJ3yDUX_Q",
    authDomain: "ecommerce-f6bae.firebaseapp.com",
    databaseURL: "https://ecommerce-f6bae.firebaseio.com",
    projectId: "ecommerce-f6bae",
    storageBucket: "ecommerce-f6bae.appspot.com",
    messagingSenderId: "841858661381",
    appId: "1:841858661381:web:024c2ac4b338076f1f706c",
    measurementId: "G-M7E813JY0E"
  },
  productsUrl: 'https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-products/',
  menuUrl: 'https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-menu/',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
