<aside class="news-aside">
  <div class="aside-title" (click)="toggleAsideDropdown()">
    <h4 class="title">Novidades</h4>
    <span #asideDropdown><i class="fa fa-angle-up"></i></span>
  </div>
  <div class="aside-list" [ngClass]="{'show' : asideDropdownVisible}">
    <ul>
      <li *ngFor="let product of news">
        <div class="product-card">
          <img [src]="product.photos" alt="Card Image" />
          <div class="product-card-body">
            <h3>{{ product.name }}</h3>
            <p>
              {{ 
                product.moedaCode === 'USD' 
                ? 'U$'
                : product.moedaCode === 'BRL'
                ? 'R$'
                : product.moedaCode === 'EUR'
                ? '€'
                : 'R$'
              }} 
              {{ product.price }}
            </p>
            <button class="btn btn-secondary" [routerLink]="'/product-description/' + product.id">
              <a [routerLink]="'/product-description/' + product.id">Comprar</a>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</aside>