import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { first } from 'rxjs/operators';
import { CategoryService } from 'src/app/shared/service/category.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories=[]
  constructor(private catergoryService:CategoryService) { }
  
  // collapse toggle
  ngOnInit() {

    this.loadCategoria();


    $('.collapse-block-title').on('click', function(e) {
        e.preventDefault;
        var speed = 300;
        var thisItem = $(this).parent(),
          nextLevel = $(this).next('.collection-collapse-block-content');
        if (thisItem.hasClass('open')) {
          thisItem.removeClass('open');
          nextLevel.slideUp(speed);
        } else {
          thisItem.addClass('open');
          nextLevel.slideDown(speed);
        }
    });
  }
  loadCategoria(){
    this.catergoryService.getCategorias().subscribe(categories => {
      this.categories = categories.map(item => {
        let mostrarValue = item['MostrarMenu'] != null && item['MostrarMenu'] !== undefined ? item['MostrarMenu'] : 'sim';
        if (typeof mostrarValue == 'string') {
          let value = mostrarValue.toLowerCase();
          item['MostrarMenu'] = value === 'sim' || value === 'true';
        }
        return {
          ...item
        }
      })
    })
    // this.catergoryService.getCategorias().snapshotChanges().pipe(first()).subscribe((categorias)=>{
    //   this.categories=categorias.map(items=>{
    //     let data:any=items.payload.doc.data();
    //     let mostrarValue = data['MostrarMenu'] != null && data['MostrarMenu'] !== undefined ? data['MostrarMenu'] : 'sim';
    //     let show = false;
    //     if (typeof mostrarValue == 'string') {
    //       let value = mostrarValue.toLowerCase();
    //       data['MostrarMenu'] = value === 'sim' || value === 'true';
    //     }
    //     return {
    //       ...data,id:items.payload.doc.id
    //     }
    //   })
    // })
  }

  // For mobile view
  public mobileFilterBack() {
     $('.collection-filter').css("left", "-365px");
  }

}
