import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CategoryService } from 'src/app/shared/service/category.service';
import { CategoriaService } from 'src/app/shared/services/categoria.service';

@Component({
  selector: 'app-product-left-aside',
  templateUrl: './product-left-aside.component.html',
  styleUrls: ['./product-left-aside.component.css']
})
export class ProductLeftAsideComponent implements OnInit {
  asideDropdownVisible: boolean = true;
  categories: any[] = [];

  @ViewChild('asideDropdown') asideDropdown!: ElementRef;


  constructor(
    private categoryService: CategoriaService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadCategories();
    this.checkScreenWidth();
  }



  loadCategories(){
    this.categoryService.getCategorias().subscribe(allCategories => {
      this.categories = allCategories.filter(category => category.MostrarMenu == true);
    }, error => {
      console.log(error);
    })
  }

  navigateToCategory(categoryId: string) {
    // Navigate to the same route, but add a navigation extra to trigger a reload
    this.router.navigate(['/product-category-list', categoryId], { queryParamsHandling: 'merge', replaceUrl: true })
      .then(() => {
        // Triggering the navigation again to force a reload
        this.router.navigate(['/product-category-list', categoryId]);
      });
  }

  // Aside Dropdown
  checkScreenWidth(){
    if(window.innerWidth <= 768){
      this.asideDropdownVisible = false;
    }
  }

  toggleAsideDropdown(){
    this.asideDropdownVisible = !this.asideDropdownVisible;
    this.rotateAsideIcon();
  }

  rotateAsideIcon(){
    const rotationValue = this.asideDropdownVisible ? '180deg' : '0deg';
    this.asideDropdown.nativeElement.querySelector('i').style.transform = `rotate(${rotationValue})`;
  }
}
