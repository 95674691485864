<div class="search-cart">
  <div class="search">
    <button class="search-btn" (click)="toggleSearchInput()">
      <img src="/assets/images/search.png" alt="Ícone de pesquisar" />
    </button>
    <input
      type="search"
      name=""
      placeholder="Pesquisar"
      [ngClass]="{ visible: inputVisible }"
      (blur)="hideInput()"
      #searchInput
    />
  </div>
  <div class="cart" (click)="handleMobileView()">
    <button class="cart-btn">
      <span class="cart-quantity" *ngIf="cartItems">{{
        cartItems.length
      }}</span>
      <img src="/assets/images/cart.png" alt="Ícone de carrinho" />
    </button>
    <!-- CART DROPDOWN -->
    <app-cart-dropdown></app-cart-dropdown>
  </div>
</div>
