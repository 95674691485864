import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse }
from '@angular/common/http';

import { Observable, of, throwError } from "rxjs";
import { AuthenticationService } from '../authentication.service';
import { catchError, delay, switchMap, tap, } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

    retryCount = 3;
    retryWaitMilliSeconds = 1000;

    constructor(private auth: AuthenticationService, private router: Router,) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request.url.includes('signin') && this.auth.token && this.auth.token !== '') {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.auth.token
                }
            });
        }
    
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error.message == 'Invalid token.' || error.error.message == 'Authorization not found.') {
                    return this.auth.refreshTokenObs().pipe(
                        switchMap((newToken) => {
                            console.log('invalid token, retrying: ' + newToken);
                            const authReq = request.clone({
                                setHeaders: {
                                    Authorization: 'Bearer ' + newToken
                                }
                            });
                            return next.handle(authReq);
                        }),
                        catchError((refreshTokenError) => {
                            this.router.navigate(['/login']);
                            return throwError('Unable to refresh token.');
                        })
                    );
                } else if (error.error.message == 'Authorization not found.') {
                    this.router.navigate(['/login']);
                    return throwError('Usuário não está logado.');
                }
    
                return throwError(error.error.message);
            })
        );
    }    

    exists(element: any): boolean {
        return element !== null && element !== undefined;
    }
}