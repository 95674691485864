import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, from, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(
    private db: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private router:Router,
    private toast:ToastrService,
    private http: HttpClient
  ) {
    this.token = localStorage.getItem(this.localStorageKey);
    this.isAnonymous = localStorage.getItem(this.anonStorageKey) == 'isNotAnon' ? false : true;
    this.isLoggedIn().subscribe(state => {
      if (this.loginStatusSubject == null) {
        this.loginStatusSubject = new BehaviorSubject<boolean>(state);
      } else {
        this.loginStatusSubject.next(state);
      }
    })
  }
  private baseAuthUrl = "https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-auth";
  private localStorageKey = 'authToken'
  private anonStorageKey = 'authIsAnon'
  user: Observable<User>;

  private isRefreshing: boolean = false;

  public token: string = '';
  public isAnonymous: boolean = false;

  private refreshingSubject: BehaviorSubject<boolean>;
  private refreshTokenObservable: Observable<any>;

  public loginStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loginStatus$: Observable<boolean>;

  async signUp(signupData: SignUp): Promise<{ success: boolean, message?: string, error?: any }> {
    try {
      // Call signUpRequest to sign up through the backend
      const response = await this.signUpRequest(signupData);

      // If successful, return the success status
      if (response && response.id) {
        return { success: true };
      } else {
        // Handle the case where the response does not contain user ID
        return { success: false, message: response.message };
      }
    } catch (error) {
      console.log(`Error: ${error}`);
        // Return the error in the case of a failure
        return { success: false, error };
    }
}

  async login(email: string, password: string): Promise<{ success: boolean, error?: any }> {
    try {
        const userCredential = await this.angularFireAuth.signInWithEmailAndPassword(email, password);
        const token = await userCredential.user.getIdToken(true);
        
        this.updateToken(token);

        let user = {
            uid: userCredential.user.uid,
            email: email
        };

        this.user = of(user);

        this.updateAnonStatus(false);
        this.loginStatusSubject.next(true);
        
        return { success: true };
      } catch (error) {
        // Handle error, you can also pass the error message if needed
        this.updateAnonStatus(true);
        this.loginStatusSubject.next(false);
        return { success: false, error };
    }
  }


  async loginRequest(userMail, userPass): Promise<LoginResponse> {
    const url = `${this.baseAuthUrl}/signin`;
    const body = JSON.stringify({email: userMail, password: userPass});
    const headers = { 'content-type': 'application/json' };
    return this.http.post<LoginResponse>(url, body, {headers}).toPromise();
  }

  async signUpRequest(signupData: SignUp): Promise<SignupResponse> {
    const url = `${this.baseAuthUrl}/signup`;
    const body = JSON.stringify(signupData);
    const headers = { 'content-type': 'application/json' };
    return this.http.post<SignupResponse>(url, body, {headers}).toPromise();
  }

  resetPasswordRequest(email: string): Observable<any> {
    const url = `${this.baseAuthUrl}/new-password`;
    const body = JSON.stringify({email: email});
    const headers = { 'content-type': 'application/json' };
    return this.http.post<any>(url, body, {headers});
  }

  async logout() {
    this.angularFireAuth.signOut();
    localStorage.removeItem(this.localStorageKey);
    this.updateAnonStatus(true);
    this.loginStatusSubject.next(false);
  }

  isLoggedIn(): Observable<boolean> {
    return this.angularFireAuth.authState.pipe(
      take(1),
      map(user => !!user && !this.isAnonymous)
    );
  }

  private updateToken(newToken: string) {
    localStorage.setItem(this.localStorageKey, newToken);
    this.token = newToken;
  }

  private updateAnonStatus(status: boolean) {
    localStorage.setItem(this.anonStorageKey, status ? 'isAnon' : 'isNotAnon');
    this.isAnonymous = status;
  }

  refreshTokenObs(): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      this.refreshTokenObservable = new Observable(observer => {
        this.angularFireAuth.user.subscribe(user => {
          if (user.displayName !== null) {
            // User is logged in, proceed with token refresh
            from(user.getIdToken()).subscribe(newToken => {
              this.updateToken(newToken);
              this.isRefreshing = false;
              this.updateAnonStatus(false);
              this.loginStatusSubject.next(true);
              observer.next(newToken);
              observer.complete();
            }, error => {
              this.isRefreshing = false;
              this.logout();
              observer.error(error);
            });
          } else {
            // No user is logged in, sign in anonymously first
            from(this.angularFireAuth.signInAnonymously()).subscribe(() => {
              // After signing in, get the ID token
              from(this.angularFireAuth.user).subscribe(user => {
                if (user) {
                  from(user.getIdToken()).subscribe(newToken => {
                    this.updateToken(newToken);
                    this.isRefreshing = false;
                    this.updateAnonStatus(true);
                    this.loginStatusSubject.next(false);
                    observer.next(newToken);
                    observer.complete();
                  }, error => {
                    this.isRefreshing = false;
                    this.logout();
                    observer.error(error);
                  });
                }
              }, error => {
                this.isRefreshing = false;
                this.logout();
                observer.error(error);
              });
            }, error => {
              this.isRefreshing = false;
              this.logout();
              observer.error(error);
            });
          }
        }, error => {
          this.isRefreshing = false;
          this.logout();
          observer.error(error);
        });
      });
    }

    return this.refreshTokenObservable;
  }
}

interface LoginResponse {
  token: string;
  uid: string,
  roles: role[]
}

interface SignupResponse {
  message: string,
  id?: string
}

export type role = {
  id?: string,
  name: string,
  displayName: string,
  description: string
}

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
}

export interface SignUp {
  name: string,
  last_name: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  phone_number: string
}