import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorLoggerService } from './error-logger.service';

@Injectable({
    providedIn: "root",
})
export class GlobalErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    const errorLoggerService = this.injector.get(ErrorLoggerService);
    console.log(`Catched a error in global. The error: ${error}`);
    console.log('Type of error:', typeof error);
    errorLoggerService.logError(error);
    super.handleError(error);
  }
}
