import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ErrorLoggerService {
  headers = { "content-type": "application/json" };
  errorLoggerUrl =
    "https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-auth/front-error";
  lasterror = null;

  constructor(private http: HttpClient) {}

  public logError(error) {
    if (this.lasterror == error) {
      return;
    } else {
      this.lasterror = error;
    }

    try {
      let errorString = JSON.stringify(
        error,
        Object.getOwnPropertyNames(error)
      );
      const body = JSON.stringify({ error: { errorToString: errorString } });
      const headers = { "Content-Type": "application/json" };
      this.http
        .post<any>(this.errorLoggerUrl, body, { headers: headers })
        .subscribe(
          (response) => {
            console.log("Error logged succesfully");
          },
          (responseError) => {
            console.log("error on logging it: " + responseError);
          }
        );
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }
}
