<!-- breadcrumb start -->
<div class="breadcrumb-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h2>produto</h2>
        </div>
      </div>
      <div class="col-sm-6">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="''">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">produto</li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
<!-- section start -->
<section class="section-b-space">
  <div class="collection-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-3 collection-filter">
          <product-details-sidebar></product-details-sidebar>
        </div>
        <div class="col-lg-9 col-sm-12 col-xs-12">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="filter-main-btn mb-2">
                  <span class="filter-btn" (click)="mobileSidebar()"
                    ><i class="fa fa-bars" aria-hidden="true"></i> sidebar</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <ngx-slick-carousel
                  class="product-slick"
                  id="p-zoom"
                  #slickModal="slick-carousel"
                  [config]="slideConfig"
                >
                  <div ngxSlickItem *ngFor="let image of product.photos">
                    <div>
                      <ngx-img-zoom
                        [previewImageSrc]="image"
                        [zoomImageSrc]="image"
                        [imgStyle]="
                          'width:100%; height:100%;pointer-events: none;'
                        "
                        [resultStyle]="
                          'background-repeat: no-repeat; z-index: 2; position:absolute;background-size: 1614px 2064px;-webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); top: 0;left: 100%;pointer-events: none'
                        "
                        [lensStyle]="'width:100px; height:100px'"
                        [containerStyle]="
                          'position: relative;pointer-events: none'
                        "
                        (mouseover)="onMouseOver()"
                        (mouseout)="onMouseOut()"
                      >
                      </ngx-img-zoom>
                    </div>
                  </div>
                </ngx-slick-carousel>
                <div class="row">
                  <div class="col-12 p-0">
                    <ngx-slick-carousel
                      class="slider-nav"
                      #slickModal="slick-carousel"
                      [config]="slideNavConfig"
                    >
                      <div ngxSlickItem *ngFor="let image of product.photos">
                        <div>
                          <img [src]="image" alt="" class="img-fluid" />
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 rtl-text">
                <div class="product-right">
                  <h2>{{ product.name }}</h2>
                  <h4>
                    <del *ngIf="product.priceWhitoutDiscount">
                      {{
                        product.priceWhitoutDiscount
                          | currency : product.moedaCode
                      }}
                    </del>
                    <!-- <span>{{ product.discount }}% off</span> -->
                  </h4>
                  <h3 *ngIf="!productsService?.catalogMode">
                    {{ product.price | currency : product.moedaCode }}
                  </h3>
                  <h3 class="td-color" *ngIf="product['moedaCode'] != 'BRL'">
                    {{
                      product.price
                        | calculateBRValue : product.moeda
                        | async
                        | currency : "BRL"
                    }}
                  </h3>

                  <ul
                    class="color-variant"
                    *ngIf="product.variants && !productsService?.catalogMode"
                  >
                    <li
                      [class]="variant.color"
                      (click)="slickModal.slickGoTo(i)"
                      *ngFor="let variant of product.variants; let i = index"
                    ></li>
                  </ul>
                  <div
                    class="pro_inventory"
                    *ngIf="
                      product.stock != 0 &&
                      product.stock <= 5 &&
                      !productsService?.catalogMode
                    "
                  >
                    <p class="active">
                      Corra! Só temos {{ product.stock }} em estoque.
                    </p>
                    <div class="inventory-scroll nostripes active">
                      <span style="width: 95%"></span>
                    </div>
                  </div>
                  <div class="border-product" *ngIf="product.shortDescription">
                    <p
                      [innerHTML]="product.shortDescription"
                      class="quebra-linha"
                    ></p>
                    <div
                      class="scroll-buttons"
                      *ngIf="product.shortDescription"
                    >
                      <span
                        style="
                          cursor: pointer;
                          color: blue;
                          text-decoration: underline;
                        "
                        (click)="scroll(descrip)"
                        >Saiba mais</span
                      >
                    </div>
                  </div>

                  <div
                    class="product-description"
                    *ngIf="!productsService?.catalogMode"
                  >
                    <h6
                      class="product-title size-text border-product"
                      *ngIf="product.estoques"
                    >
                      Selecionar Tamanho
                    </h6>
                    <!--  <div class="size-box" *ngIf="product.size">
                      <ul>
                        <li
                          [ngClass]="{ active: selectedSize == size }"
                          *ngFor="let size of product.sizes"
                        >
                          <a
                            (click)="changeSizeVariant(size)"
                            >{{ size }}</a
                          >
                        </li>
                      </ul>
                    </div> -->
                    <div class="size-box" *ngIf="product.estoques">
                      <ul>
                        <li
                          [ngClass]="{
                            active: selectedSize.tamanho == estoque.tamanho
                          }"
                          *ngFor="let estoque of product.estoques"
                        >
                          <a (click)="changeSizeVariant(estoque)">{{
                            estoque.tamanho
                          }}</a>
                        </li>
                      </ul>
                    </div>

                    <div class="product-description" *ngIf="product.estoques">
                      <h5
                        class="avalibility"
                        *ngIf="counter <= selectedSize.quantidade"
                      >
                        <span>Em estoque</span>
                      </h5>
                      <h5
                        class="avalibility"
                        *ngIf="counter > selectedSize.quantidade"
                      >
                        <span>Sem estoque</span>
                      </h5>
                      <h6 class="product-title border-product">quantidade</h6>
                      <div class="qty-box" style="margin-bottom: 10px">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                              type="button"
                              (click)="decrement()"
                              class="btn quantity-left-minus"
                              data-type="minus"
                              data-field=""
                            >
                              <i class="ti-angle-left"></i>
                            </button>
                          </span>
                          <input
                            type="text"
                            name="quantity"
                            disabled
                            class="form-control input-number"
                            value="{{ counter }}"
                          />
                          <span
                            class="input-group-prepend"
                            *ngIf="counter != selectedSize.quantidade"
                          >
                            <button
                              type="button"
                              (click)="increment()"
                              class="btn quantity-right-plus"
                              data-type="plus"
                              data-field=""
                            >
                              <i class="ti-angle-right"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="product-description"
                      *ngIf="product.campos || product.turmas"
                    >
                      <h6
                        class="product-title size-text border-product"
                        *ngIf="product.campos"
                      >
                        Campos Necessários
                      </h6>
                      <ng-container *ngIf="showTurmas == true">
                        <mat-form-field appearance="outline">
                          <mat-select
                            [placeholder]="
                              product.turmasNome
                                ? product.turmasNome
                                : 'Modalidade'
                            "
                            [(ngModel)]="selectedTurma"
                            name="item"
                          >
                            <mat-option
                              [value]="turma"
                              *ngFor="let turma of product.turmas"
                              (click)="Turma()"
                            >
                              {{ turma.nome }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </ng-container>

                      <form [formGroup]="genericForm">
                        <div *ngFor="let campo of product.campos">
                          <mat-form-field
                            appearance="outline"
                            *ngIf="
                              campo.tipo != 'Arquivo' &&
                              campo.tipo != 'Telefone' &&
                              campo.tipo != 'Data' &&
                              campo.tipo != 'CPF' &&
                              campo.tipo != 'Multi'
                            "
                          >
                            <mat-label>{{ campo.nome | campoNome }}</mat-label>
                            <input
                              matInput
                              [formControlName]="campo.nome"
                              [required]="campo.required"
                              autocomplete="off"
                            />
                          </mat-form-field>
                          <ng-container *ngIf="campo.tipo == 'Data'">
                            <mat-form-field>
                              <mat-label>{{
                                campo.nome | campoNome
                              }}</mat-label>
                              <input
                                matInput
                                [formControlName]="campo.nome"
                                [imask]="{ mask: '00/00/0000' }"
                                placeholder="00/00/0000"
                                [unmask]="false"
                                [required]="campo.required"
                                autocomplete="off"
                              />
                            </mat-form-field>
                          </ng-container>
                          <ng-container *ngIf="campo.tipo == 'CPF'">
                            <mat-form-field>
                              <mat-label>{{
                                campo.nome | campoNome
                              }}</mat-label>
                              <input
                                matInput
                                [formControlName]="campo.nome"
                                [imask]="{ mask: '000.000.000-00' }"
                                placeholder="000.000.000-00"
                                [unmask]="false"
                                [required]="campo.required"
                                autocomplete="off"
                              />
                            </mat-form-field>
                          </ng-container>
                          <ng-container *ngIf="campo.tipo == 'Arquivo'">
                            <mat-form-field appearance="outline">
                              <mat-label>{{
                                campo.nome | campoNome
                              }}</mat-label>
                              <ngx-mat-file-input
                                [formControlName]="campo.nome"
                                (change)="onSelectFile(campo, $event)"
                                placeholder="Insira um arquivo"
                              >
                              </ngx-mat-file-input>
                              <mat-icon matSuffix>folder</mat-icon>
                            </mat-form-field>
                          </ng-container>

                          <ng-container *ngIf="campo.tipo == 'Telefone'">
                            <mat-form-field appearance="outline">
                              <mat-label>{{
                                campo.nome | campoNome
                              }}</mat-label>
                              <input
                                matInput
                                [formControlName]="campo.nome"
                                [imask]="{ mask: '(00) 00000-0000' }"
                                placeholder="(00) 00000-0000"
                                [unmask]="false"
                                [required]="campo.required"
                                autocomplete="off"
                              />
                            </mat-form-field>
                          </ng-container>

                          <ng-container *ngIf="campo.tipo == 'Multi'">
                            <mat-form-field appearance="outline">
                              <mat-label>{{
                                campo.nome | campoNome
                              }}</mat-label>
                              <mat-select
                                matInput
                                [formControlName]="campo.nome"
                                [required]="campo.required"
                                name="item"
                              >
                                <mat-option
                                  [value]="valor"
                                  *ngFor="let valor of campo.multiValores"
                                  (click)="Turma()"
                                >
                                  {{ valor }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </ng-container>
                        </div>
                      </form>
                    </div>

                    <div
                      class="product-description"
                      *ngIf="product.quantidade || product.turmas"
                      style="display: none"
                    >
                      <h5 class="avalibility" *ngIf="counter <= product.stock">
                        <span>Em estoque</span>
                      </h5>
                      <h5 class="avalibility" *ngIf="counter > product.stock">
                        <span>Sem estoque</span>
                      </h5>
                      <div *ngIf="product.turmas">
                        <h5
                          class="avalibility"
                          *ngIf="selectedTurmaVagasDisponiveis <= 0"
                        >
                          <span>Sem vagas</span>
                        </h5>
                      </div>
                      <h6 class="product-title border-product">quantidade</h6>
                      <div class="qty-box">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                              type="button"
                              (click)="decrement()"
                              class="btn quantity-left-minus"
                              data-type="minus"
                              data-field=""
                            >
                              <i class="ti-angle-left"></i>
                            </button>
                          </span>
                          <input
                            type="text"
                            name="quantity"
                            disabled
                            class="form-control input-number"
                            value="{{ counter }}"
                          />
                          <span
                            class="input-group-prepend"
                            *ngIf="counter != product.quantidade"
                          >
                            <button
                              type="button"
                              (click)="increment()"
                              class="btn quantity-right-plus"
                              data-type="plus"
                              data-field=""
                            >
                              <i class="ti-angle-right"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="product-buttons"
                    *ngIf="!productsService?.catalogMode"
                  >
                    <button
                      [routerLink]=""
                      (click)="addToCart(product, counter)"
                      class="btn btn-solid"
                      [disabled]="
                        counter > product.stock ||
                        genericForm.invalid ||
                        (product.turmas &&
                          product.turmas.length > 0 &&
                          counter > selectedTurmaVagas) ||
                        counter > selectedSize.quantidade
                      "
                    >
                      Adicionar ao carrinho
                    </button>
                    <button
                      [routerLink]=""
                      (click)="buyNow(product, counter)"
                      class="btn btn-solid"
                      [disabled]="
                        counter > product.stock ||
                        genericForm.invalid ||
                        counter > selectedTurmaVagas ||
                        counter > selectedSize.quantidade
                      "
                    >
                      Comprar Agora
                    </button>
                  </div>
                  <div class="border-product" *ngIf="product.shortDetails">
                    <h6 class="product-title">detalhes do produto</h6>
                    <p>{{ product.shortDetails }}</p>
                  </div>
                  <div class="border-product" *ngIf="false">
                    <h6 class="product-title">share it</h6>
                    <div class="product-icon">
                      <ul class="product-social">
                        <li>
                          <a href=""><i class="fa fa-facebook"></i></a>
                        </li>
                        <li>
                          <a href="https://plus.google.com/discover"
                            ><i class="fa fa-google-plus"></i
                          ></a>
                        </li>
                        <li>
                          <a href="https://twitter.com/"
                            ><i class="fa fa-twitter"></i
                          ></a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/"
                            ><i class="fa fa-instagram"></i
                          ></a>
                        </li>
                      </ul>
                      <form
                        class="d-inline-block"
                        *ngIf="!productsService?.catalogMode"
                      >
                        <button
                          class="wishlist-btn"
                          (click)="addToWishlist(product)"
                        >
                          <i class="fa fa-heart"></i
                          ><span class="title-font">Add To WishList</span>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div class="border-product" *ngIf="false">
                    <h6 class="product-title">Time Reminder</h6>
                    <div class="timer">
                      <p id="timer">
                        <span
                          >25
                          <span class="padding-l">:</span>
                          <span class="timer-cal">Days</span>
                        </span>
                        <span
                          >22
                          <span class="padding-l">:</span>
                          <span class="timer-cal">Hrs</span>
                        </span>
                        <span
                          >13
                          <span class="padding-l">:</span>
                          <span class="timer-cal">Min</span>
                        </span>
                        <span
                          >57
                          <span class="timer-cal">Sec</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="tab-product m-0">
            <div class="row">
              <div class="col-sm-12 col-lg-12" #descrip>
                <ul
                  class="nav nav-tabs nav-material"
                  id="top-tab"
                  role="tablist"
                >
                  <li class="nav-item" *ngIf="product.description">
                    <a
                      class="nav-link active"
                      id="top-home-tab"
                      data-toggle="tab"
                      href="#top-home"
                      role="tab"
                      aria-selected="true"
                    >
                      <i class="icofont icofont-ui-home"></i>Descrição</a
                    >
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item" *ngIf="product.details">
                    <a
                      class="nav-link {{
                        product.details && !product.description ? 'active' : ''
                      }}"
                      id="profile-top-tab"
                      data-toggle="tab"
                      href="#top-profile"
                      role="tab"
                      aria-selected="false"
                      ><i class="icofont icofont-man-in-glasses"></i>Detalhes</a
                    >
                    <div class="material-border"></div>
                  </li>
                </ul>
                <div class="tab-content nav-material" id="top-tabContent">
                  <div
                    class="tab-pane fade {{
                      product.description ? 'show active' : ''
                    }}"
                    id="top-home"
                    role="tabpanel"
                    aria-labelledby="top-home-tab"
                  >
                    <p
                      [innerHTML]="product.description"
                      class="quebra-linha"
                    ></p>
                  </div>
                  <div
                    class="tab-pane fade {{
                      product.details && !product.description
                        ? 'show active'
                        : ''
                    }}"
                    id="top-profile"
                    role="tabpanel"
                    aria-labelledby="profile-top-tab"
                  >
                    <p [innerHTML]="product.details" class="quebra-linha"></p>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="top-review"
                    role="tabpanel"
                    aria-labelledby="review-top-tab"
                  >
                    <!-- <form  class="theme-form">
                      <div class="form-row">
                        <div class="col-md-12">
                          <div class="media">
                            <label>Rating</label>
                            <div class="media-body ml-3">
                              <div class="rating three-star">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label for="name">Name</label>
                          <input
                            type="text"
                            class="form-control"
                            id="name"
                            placeholder="Enter Your name"
                            required
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="email">Email</label>
                          <input
                            type="text"
                            class="form-control"
                            id="email"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <div class="col-md-12">
                          <label for="review">Review Title</label>
                          <input
                            type="text"
                            class="form-control"
                            id="review"
                            placeholder="Enter your Review Subjects"
                            required
                          />
                        </div>
                        <div class="col-md-12">
                          <label for="review">Review Title</label>
                          <textarea
                            class="form-control"
                            placeholder="Wrire Your Testimonial Here"
                            id="exampleFormControlTextarea1"
                            rows="6"
                          ></textarea>
                        </div>
                        <div class="col-md-12">
                          <button class="btn btn-solid" type="submit">
                            Submit YOur Review
                          </button>
                        </div>
                      </div>
                    </form> -->
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section ends -->
<!-- Modal size chart -->
<div
  class="modal fade"
  id="sizemodal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ product.name }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <img src="assets/images/size-chart.jpg" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</div>
