<app-wrapper-loading-spinner [isLoading]="isLoading"></app-wrapper-loading-spinner>

<div class="dropdown-cart">
  <div class="dropdown-cart-body">
    <div class="cart-products-wrapper">
      <!-- CART ITEM -->
      <ng-container *ngIf="cartItem.length > 0">
        <div class="cart-body-top" *ngFor="let item of cartItem">
          <div class="dropdown-cart-img">
            <img [src]="item.image" alt="" />
          </div>
          <div class="dropdown-cart-info">
            <p class="dropdown-cart-prod-name">{{ item.name }}</p>
            <p class="dropdown-cart-prod-price">
              {{ 
                item.code === 'USD' 
                ? 'U$'
                : item.code === 'BRL'
                ? 'R$'
                : item.code === 'EUR'
                ? '€'
                : 'R$'
              }} 
              {{ item.price }}
            </p>
            <button
              class="cart-delete-prod"
              (click)="EraseProduct(item.cartItem)"
            >
              <img src="/assets/images/icons8-lixeira-48-laranja.png" alt="" />
            </button>
          </div>
        </div>
      </ng-container>

      <!-- EMPTY CART -->
      <ng-container *ngIf="cartItem.length <= 0">
        <div class="empty-cart">
          <p>Carrinho vazio</p>
        </div>
      </ng-container>
    </div>

    <!-- BOTTOM -->
    <ng-container *ngIf="cartItem.length > 0">
      <div class="cart-body-bottom">
        <div class="subtotal">
          <p>Subtotal:</p>
          <p>R$ {{ subTotal }}</p>
        </div>
        <div class="redirections">
          <button class="btn-link" [routerLink]="['/cart']">
            Ver carrinho
          </button>

          <button class="btn-link" [routerLink]="['/checkout']">
            Checkout
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
