import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { FrontPageService } from 'src/app/shared/service/front-page.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit {
  isLoading: boolean = true;
  slides;
  source;
  subscriptions;
  carouselConfig = {
    autoplay: false,
    // autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    arrows: true,
    dots: true,
  };

  constructor(
    private router: Router,
    private frontpageService: FrontPageService
  ) { }

  ngOnInit(){
    this.isLoading = true;
    this.getCarousel();
  }

  getCarousel(){
    this.frontpageService.getCarousel().subscribe(response => {

      let filter = response.filter((item) => {
        return item['active'];
      })

      this.slides = filter.map((carouselItems) => {
        return {
          ...carouselItems,
          image: carouselItems['imgSrc'],
          buttonLink: carouselItems['link'],
          imageRight: 'assets/images/logo-vessel-white.png',
          id: carouselItems.id
        }
      });
      console.log(this.slides);
    })
    this.isLoading = false;
  }

  // Handle slide free style classes
  getContainerClass(slide){
    const classes = [];
    if(slide.blockNums === '1'){ 
      // 1 block
      if(slide.oneBlock === 'oneBlockButton'){ 
        // Show button side
        classes.push('oneBlockShowButton');

        // Button position
        if(slide.oneBlockPosition === 'oneBlockTop'){ // Button on top
          classes.push('oneBlockButtonTop');

        } else if(slide.oneBlockPosition === 'oneBlockMiddle'){ // Button on mid
          classes.push('oneBlockButtonMiddle');

        } else if(slide.oneBlockPosition === 'oneBlockBottom'){ // Button on bottom
          classes.push('oneBlockButtonBottom')
        }

      } else if(slide.oneBlock === 'oneBlockLogo'){ 
        // Show logo side
        classes.push('oneBlockShowLogo');

        // Logo position
        if(slide.oneBlockPosition === 'oneBlockTop'){ // Logo on top
          classes.push('oneBlockLogoTop');

        } else if(slide.oneBlockPosition === 'oneBlockMiddle'){ // Logo on mid
          classes.push('oneBlockLogoMiddle');

        } else if(slide.oneBlockPosition === 'oneBlockBottom'){ // Logo on bottom
          classes.push('oneBlockLogoBottom')
        }
      }

    } else if(slide.blockNums === '2'){ 
      // 2 Blocks

      // Blocks order
      if(slide.twoBlocksLeftRight === 'buttonLeft'){ 
        // Button-Logo
        classes.push('buttonLeft');

      } else if(slide.twoBlocksLeftRight === 'buttonRight'){ 
        // Logo-button
        classes.push('buttonRight');
      }

      // Button position
      if(slide.buttonVerticalPosition === 'buttonVertTop'){
        classes.push('buttonTop');

      } else if(slide.buttonVerticalPosition === 'buttonVertMid'){
        classes.push('buttonMid');

      } else if(slide.buttonVerticalPosition === 'buttonVertBot'){
        classes.push('buttonBot');

      }

      // Logo position
      if(slide.logoVerticalPosition === 'logoVertTop'){
        classes.push('logoTop');

      } else if(slide.logoVerticalPosition === 'logoVertMid'){
        classes.push('logoMid');

      } else if(slide.logoVerticalPosition === 'logoVertBot'){
        classes.push('logoBot');

      }
    }
    return classes;
  }

  redirectTo(link){
    window.location.href = link;
  }
}
