import { Routes } from "@angular/router";

import { MainComponent } from "./main/main.component";
import { DemoComponent } from "./demo/demo.component";

export const rootRouterConfig: Routes = [

  // {
  //   path: 'demo',
  //   component: DemoComponent
  // },
  {
    path: "",
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./shop/shop.module").then((m) => m.ShopModule),
      },
      {
        path: "pages",
        loadChildren: () =>
          import("./pages/pages.module").then((m) => m.PagesModule),
      },
    
    ],
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch:'full'
  },
];
