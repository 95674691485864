<!-- Adicionar ao carrinho modal popup start-->
<div class="modal fade bd-example-modal-lg theme-modal cart-modal addTocartModal" [id]="'addtocart'+product.id" tabindex="-1" role="dialog" aria-hidden="true" *ngFor="let product of products">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body modal1">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="col-12">
                            <div class="modal-bg addtocart">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="media">
                                    <a [routerLink]="['/home/left-sidebar/product', product.id]">
                                        <img class="img-fluid pro-img" [src]="product.photos[0]" alt="">
                                    </a>
                                    <div class="media-body align-self-center text-center">
                                        <a [routerLink]="['/home/left-sidebar/product', product.id]">
                                            <h6>
                                                <i class="fa fa-check"></i>Item
                                                <span>{{product.name}}</span>
                                                <span> successfully added to your Cart</span>
                                            </h6>
                                        </a>
                                        <div class="buttons">
                                            <a [routerLink]="['/home/cart']" class="view-cart btn btn-solid">Your cart</a>
                                            <a [routerLink]="['/home/checkout']" class="checkout btn btn-solid">Check out</a>
                                            <a [routerLink]="['/home/left-sidebar/collection/all']" class="continue btn btn-solid">Continue shopping</a>
                                        </div>

                                        <div class="upsell_payment">
                                            <img src="assets/images/payment_cart.png" class="img-fluid" alt="">
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="product-section">
                                    <div class="col-12 product-upsell text-center">
                                        <h4>Customers who bought this item also.</h4>
                                    </div>
                                     <div class="row" id="upsell_product">
                                        <div class="product-box col-sm-3 col-6" *ngFor="let relatedProduct of relatedProducts(product) | slice:0:4">
                                            <div class="img-wrapper">
                                                <div class="front">
                                                    <a [routerLink]="['/home/left-sidebar/product', relatedProduct.id]">
                                                        <img [src]="relatedproduct.photos[0]" class="img-fluid mb-1" alt="">
                                                    </a>
                                                </div>
                                                <div class="product-detail">
                                                    <h6 class="mt-0"><a [routerLink]="['/home/left-sidebar/product', relatedProduct.id]"><span>{{relatedProduct.name}}</span></a></h6>
                                                    <h4><span>{{relatedProduct.price | currency:productsService?.currency:'symbol'}}</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div> -->

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Adicionar ao carrinho modal popup end-->
