import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Product } from "../../../../shared/classes/product";
import { ProductsService } from "../../../../shared/services/products.service";
import { WishlistService } from "../../../../shared/services/wishlist.service";
import { CartService } from "../../../../shared/services/cart.service";
import { Observable, of } from "rxjs";
import * as $ from "jquery";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { AngularFireAuth } from "@angular/fire/auth";
import { finalize, first } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/storage";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { CartItem } from "src/app/shared/classes/cart-item";

@Component({
  selector: "app-product-left-sidebar",
  templateUrl: "./product-left-sidebar.component.html",
  styleUrls: ["./product-left-sidebar.component.scss"],
})
export class ProductLeftSidebarComponent implements OnInit {
  public product: Product = {};
  public counter: number = 1;
  public selectedSize: any = "";
  public selectedTurmaVagas: any = 200;
  public selectedTurmaVagasDisponiveis: any = 200;
  public sizes: [];
  public selectedTurma;
  public iStyle = "width:100%; height:100%;";
  public filesToUpload = {};
  public uploading;
  public checkoutForm: FormGroup;
  public cartItems: Observable<CartItem[]> = of([]);
  public checkoutItems: CartItem[] = [];
  public showTurmas: boolean;

  genericForm: FormGroup = this.fb.group({});
  //Get Product By Id
  constructor(
    private storage: AngularFireStorage,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    public productsService: ProductsService,
    private wishlistService: WishlistService,
    private cartService: CartService,
    private toastr: ToastrService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = params["id"];
      this.router.navigate([`/product-description/${id}`]);
      return;
      this.productsService.getProduct(id).subscribe(
        (response) => {
          const product: any = response;
          let campos = [];
          Object.keys(this.genericForm.controls).forEach((campo) => {
            campos.push(campo);
          });
          if (campos.length > 0) {
            campos.forEach((campo) => {
              this.genericForm.removeControl(campo);
              if (campo.tipo == "Multi") {
                campo.multiValores = campo.multiValores.split(";");
              }
            });
          }

          this.product = product;

          if (
            (this.elementExists(product["hasTurmas"]) &&
              this.elementExists(product["turmas"]) &&
              product.hasTurmas == true &&
              product.turmas.length > 0) ||
            (product.hasTurmas == undefined && product.turmas.length > 0)
          ) {
            this.showTurmas = true;
          } else {
            this.showTurmas = false;
          }

          if (this.product.campos) {
            this.product.campos.forEach((campo) => {
              if (campo.tipo == "Multi") {
                campo.multiValores = campo.multiValores.split(";");
                if (campo.multiValores[campo.multiValores.length - 1] == "") {
                  campo.multiValores.splice(campo.multiValores.length - 1, 1);
                }
              }
            });
          }
          if (this.product["active"]) {
            if (this.product["active"] == "false") {
              this.router.navigate([""]);
              return;
            }
          }
          if (this.product["campos"]) {
            const group: any = {};
            this.product["campos"].forEach((campo) => {
              let newValidators = [];

              // Add validators
              // Exception may be cause on old products
              if (campo["required"] == null && campo["required"] == undefined) {
                campo = { ...campo, required: true };
              }

              if (campo.required) {
                newValidators.push(Validators.required);
              }
              const tipo: string = campo.tipo.toLowerCase();
              if (tipo == "cpf") {
                newValidators.push(Validators.minLength(14));
                newValidators.push(Validators.maxLength(14));
              } else if (tipo == "data") {
                newValidators.push(Validators.minLength(10));
                newValidators.push(Validators.maxLength(10));
              } else if (tipo == "telefone") {
                newValidators.push(Validators.minLength(14));
                newValidators.push(Validators.maxLength(15));
              }

              // Add the field
              group[campo.nome] = new FormControl("", newValidators);
            });
            this.genericForm = new FormGroup(group);
          }
          if (this.product["turmas"]) {
            if (!this.product["hasTurmas"]) {
              this.genericForm.addControl("turma", new FormControl(""));
            } else {
              this.genericForm.addControl(
                "turma",
                new FormControl("", Validators.required)
              );
            }
          }
          if (this.product["estoques"]) {
            this.genericForm.addControl(
              "estoques",
              new FormControl("", Validators.required)
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  // product zoom
  onMouseOver(): void {
    document.getElementById("p-zoom").classList.add("product-zoom");
  }

  onMouseOut(): void {
    document.getElementById("p-zoom").classList.remove("product-zoom");
  }

  public slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
  };

  public slideNavConfig = {
    vertical: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".product-slick",
    arrows: false,
    dots: false,
    focusOnSelect: true,
  };

  public increment() {
    /* if (this.product.quantidade) {
      if (this.counter < parseInt(this.product.quantidade)) {
        this.counter += 1;
      }
    } else {
      if (this.counter < parseInt(this.selectedSize.quantidade)) {
        this.counter += 1;
      }
    } */
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  // For mobile filter view
  public mobileSidebar() {
    $(".collection-filter").css("left", "-15px");
  }

  // Adicionar ao carrinho
  public addToCart(product: Product, quantity) {
    console.log("produto", product);
    if (this.selectedTurmaVagasDisponiveis <= 0) {
      this.toastr.error("Não há vagas disponiveis.");
      return;
    }
    let keys = Object.keys(this.filesToUpload);
    if (keys.length <= 0) {
      this.addProductToCart(product, quantity);

      this.angularFireAuth.authState.pipe(first()).subscribe((user) => {
        if (!user) {
          this.router.navigate(["/pages/login"]);
        }
      });
    } else {
      keys.forEach((key) => {
        this.processFile(this.filesToUpload[key], key).then(() => {
          this.addProductToCart(product, quantity);

          this.angularFireAuth.authState.pipe(first()).subscribe((user) => {
            if (!user) {
              this.router.navigate(["/pages/login"]);
            }
          });
        });
      });
    }
  }

  addProductToCart(product: Product, quantity) {
    if (product.estoques) {
      this.genericForm.controls.estoques.setValue(this.selectedSize.tamanho);
    }
    if (
      this.product["campos"] ||
      this.product["turmas"] ||
      this.product["estoques"]
    ) {
      let aux_form = this.genericForm.value;
      if (product.estoques) {
        aux_form["tamanho"] = aux_form["estoques"];
        delete aux_form["estoques"];
      }
      this.product["camposCustomizados"] = this.genericForm.value;
    }
    if (quantity == 0) return false;
    this.cartService.addToCart({ ...product }, parseInt(quantity));
  }

  orderId = generatePushID();

  masterLoginTeste(user) {
    console.log(user);
    let order;
    let id: string;

    this.angularFireAuth.user.subscribe((user) => {
      if (user == null) {
        this.router.navigate([`/pages/login`]);
      }
      id = user.uid;
      console.log("this.id aqui: " + id);
      console.log("this.uid aqui: " + user.uid);

      // Order Data
      this.cartService.getItems().subscribe((allProducts) => {
        order = {
          firstname: "Teste",
          lastname: "Teste",
          phone: "0000000000",
          email: "teste@teste.com.br",
          address: "Rua Teste",
          country: "Brasil",
          bairro: "Centro",
          number: "000",
          town: "Teste",
          state: "Teste",
          postalcode: "00000000",
          document: "00000000",
          total: 0,
          produtos: allProducts,
          orderId: this.orderId,
          cliente: id,
          status: "Pago",
          metodo: "Registro manual",
          data: new Date().toUTCString(),
          parcelas: 0,
        };

        let orderIds = [];
        order.produtos.forEach((prod) => {
          orderIds.push(prod.product.id);
        });

        if (orderIds.length == 0) {
          return null;
        }

        order = { ...order, productIds: orderIds };

        // Create the requisition body
        const body = JSON.stringify({ orderData: order });
        // console.log(body);
        const headers = {
          "content-type": "application/json",
          email: user.email,
        };
        this.http
          .post<any>(
            "https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/api-getnet/save-payment",
            body,
            { headers }
          )
          .subscribe(
            (suc) => {
              // this.isLoading = false;
              let orderId = suc.id;
              this.cartService.clearCart();
              this.router.navigate(["/pages/order-success/" + orderId]);
            },
            (er) => {
              this.router.navigate(["/home/checkout"]);
              // error
              // this.isLoading = false;
              // this.toast.error("Alguma cois deu errado. Por favor, tente novamente mais tarde.")
            }
          );
      });
    });
  }

  // Adicionar ao carrinho
  public buyNow(product: Product, quantity) {
    let keys = Object.keys(this.filesToUpload);
    if (keys.length <= 0) {
      this.addProductToCart(product, quantity);

      this.angularFireAuth.authState.pipe(first()).subscribe((user) => {
        if (!user) {
          this.router.navigate(["/pages/register"]);
          return;
        }
        this.masterLoginTeste(user);
      });
    } else {
      keys.forEach((key) => {
        this.processFile(this.filesToUpload[key], key).then(() => {
          this.addProductToCart(product, quantity);

          this.angularFireAuth.authState.pipe(first()).subscribe((user) => {
            if (!user) {
              this.router.navigate(["/pages/register"]);
            }
            this.masterLoginTeste(user);
          });
        });
      });
    }
  }
  onSelectFile(campo) {
    this.filesToUpload[campo.nome] =
      this.genericForm.controls[campo.nome].value.files[0];
  }

  // Add to wishlist
  public addToWishlist(product: Product) {
    this.wishlistService.addToWishlist(product);
  }

  // Change size variant
  public changeSizeVariant(variant) {
    this.selectedSize = variant;
    this.genericForm.controls.estoques.setValue(variant.tamanho);
  }

  processFile(fileInput: any, campo) {
    return new Promise((resolve, reject) => {
      this.uploading = true;

      const file = fileInput;
      const filePath =
        "client/files/" +
        this.product.name +
        new Date().getDate() +
        "/" +
        fileInput.name;
      const ref = this.storage.ref(filePath);
      const task = ref.put(file);
      // observe percentage changes
      // get notified when the download URL is available
      task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            ref.getDownloadURL().subscribe((url) => {
              this.genericForm.controls[campo].setValue(url);
              resolve(true);
            });
          })
        )
        .subscribe();
    });
  }

  public scroll(element: any) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  Turma() {
    this.selectedTurmaVagas = this.selectedTurma.vagas;
    this.selectedTurmaVagasDisponiveis =
      this.selectedTurmaVagas - this.selectedTurma.vagasOcupadas;
    if (this.selectedTurmaVagasDisponiveis < 0)
      this.selectedTurmaVagasDisponiveis = 0;
    console.log(this.selectedTurmaVagasDisponiveis);
    this.genericForm.controls.turma.setValue(this.selectedTurma.nome);
  }

  // auxs
  elementExists(element: any): Boolean {
    return element !== null && element !== Boolean;
  }
}

export function generatePushID() {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return function () {
    var now = new Date().getTime();
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    var id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length != 20) throw new Error("Length should be 20.");

    return id;
  };
}
