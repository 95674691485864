import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

export interface ICategory {
  id: string;
  MostrarMenu: boolean | string;
  Nome: string;
  Status: string;
  imageUrl: string;
  description: string;
  value: number;
  displayValue?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class CategoriaService {
  headers = { "content-type": "application/json" };
  productsUrl =
    "https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-products/";

  constructor(private db: AngularFirestore, private http: HttpClient) {}

  getCategorias() {
    let url: string = this.productsUrl + "categories";

    return this.http.get<ICategory[]>(url, { headers: this.headers });
  }
}
