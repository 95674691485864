<!-- <header id="sticky" class="sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header">
    <div class="container">
      <app-topbar></app-topbar>
    </div>
  </div>
  <div class="container container-menu">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <div class="navbar">
              <a (click)="openNav()">
                <div class="bar-style">
                  <i class="fa fa-bars sidebar-bar" aria-hidden="true"></i>
                </div>
              </a>
              <div id="mySidenav" class="sidenav">
                <a
                  [routerLink]=""
                  class="sidebar-overlay"
                  (click)="closeNav()"
                ></a>
                <nav>
                  <div (click)="closeNav()">
                    <div class="sidebar-back text-left">
                      <i class="fa fa-angle-left pr-2" aria-hidden="true"></i>
                      Voltar
                    </div>
                  </div>
                  <app-left-menu></app-left-menu>
                </nav>
              </div>
            </div>
          </div>
          <div class="brand-logo" style="text-align: center; display: block">
            <a [routerLink]="''">
              <img
                style="height: 100px"
                src="assets/images/VESSEL-STORE.png"
                class="img-fluid logo-top"
                alt=""
              />
            </a>
          </div>
          <div class="menu-right pull-right">
            <div class="appSearch">
              <app-search></app-search>
            </div>
            <div>
              <app-header-widgets
                [shoppingCartItems]="shoppingCartItems"
              ></app-header-widgets>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header> -->
<header>
  <div class="container">
    <div class="logo">
      <a routerLink="/">
        <img src="assets/images/VESSEL-STORE.png" alt="Logo Vessel Store" />
      </a>
    </div>
    <div class="menu">
      <app-main-menu></app-main-menu>
    </div>
    <div class="extra-menu">
      <div class="menu-dropdown">
        <ul>
          <li>
            <i class="fa fa-bars"></i>
            <span>Experiências</span>
            <app-dropdown></app-dropdown>
          </li>
        </ul>
      </div>
      <app-icon-link
        classModifier="phone"
        link="tel:+554135289080"
        iconClass="fa fa-phone"
        label="Telefone: (41) 3528-9080"
      >
      </app-icon-link>

      <div
        class="user-dropdown"
        (mouseenter)="userEmail ? (showDropdown = true) : null"
        (mouseleave)="showDropdown = false"
        [ngClass]="{ 'show-dropdown': showDropdown }"
      >
        <app-icon-link
          classModifier="login"
          [link]="userEmail ? 'pages/orders-list' : 'pages/login'"
          iconClass="fa fa-user"
          [label]="userEmail ? 'Minha conta' : 'Login'"
        >
        </app-icon-link>
        <ng-container *ngIf="showDropdown && userEmail">
          <div class="profile-dropdown">
            <h4 class="dropdown-title">Conta</h4>
            <p class="user-email">{{ userEmail }}</p>
            <div class="profile-redirections">
              <div class="item-wrapper">
                <div class="icon-wrapper">
                  <i class="fa fa-user"></i>
                </div>
                <a [routerLink]="'pages/dashboard'" class="btn-link">Perfil</a>
              </div>
              <div class="item-wrapper">
                <div class="icon-wrapper"><i class="fa fa-list-alt"></i></div>
                <a [routerLink]="'pages/orders-list'" class="btn-link"
                  >Pedidos</a
                >
              </div>
              <div class="item-wrapper">
                <div class="icon-wrapper"><i class="fa fa-sign-out"></i></div>
                <a (click)="logout()" class="btn-link">Sair</a>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- HAMBURGER ICON -->
    <div class="menu-hamburger" (click)="toggleMobileMenu()">
      <span class="hamburger-img">
        <i class="fa fa-bars"></i>
      </span>
    </div>
  </div>
</header>

<!-- MENU HAMBURGER -->
<ng-container *ngIf="isMobileMenuOpen">
  <div class="overlay" [class.show]="isMobileMenuOpen" ></div>
  <div class="menu-mobile" [class.show]="isMobileMenuOpen" (click)="toggleMobileMenu()">
    <div class="container">
      <div class="menu">
        <app-main-menu></app-main-menu>
      </div>
      <div class="extra-menu">
        <div class="menu-dropdown">
          <ul>
            <li>
              <span>Experiências</span>
              <app-dropdown></app-dropdown>
            </li>
          </ul>
        </div>
        <app-icon-link
          classModifier="login"
          [link]="userEmail ? 'pages/orders-list' : 'pages/login'"
          iconClass="fa fa-user"
          [label]="userEmail ? 'Minha conta' : 'Login'"
        >
        </app-icon-link>
        <app-icon-link
          classModifier="phone"
          link="tel:+554135289080"
          iconClass="fa fa-phone"
          label="Telefone: (41) 3528-9080"
        >
        </app-icon-link>
      </div>
    </div>
  </div>
</ng-container>
