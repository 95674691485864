<app-header></app-header>
 

<div  > <!-- Left sidebar menu only -->
	
	<router-outlet></router-outlet>
	<app-whatsapp-button></app-whatsapp-button>

	<app-footer></app-footer>
 

</div>
	

