<app-wrapper-loading-spinner
  [isLoading]="isLoading"
></app-wrapper-loading-spinner>
<div class="products-list-p">
  <!-- Breadcrumb -->
  <div class="breadcrumb-s">
    <div class="container">
      <app-breadcrumb></app-breadcrumb>
      <app-search-and-cart></app-search-and-cart>
    </div>
  </div>

  <!-- Main Section -->
  <section class="products-list-s">
    <div class="container">
      <!-- Banner -->
      <div class="top-s">
        <div class="products-banners-s">
          <div class="banner-wrapper">
            <!-- Mini Banners -->
            <app-minibanner
              *ngFor="let category of currentCategoryBanner"
              [categoryName]="category.name"
              [categoryHighlight]="category.highlight"
              [categoryImage]="category.imageUrl"
              [categoryDescription]="category.description"
            >
            </app-minibanner>
          </div>
        </div>
      </div>

      <div class="main-s">
        <!-- Left sidebar -->
        <div class="left-aside">
          <app-product-left-aside></app-product-left-aside>
        </div>

        <!-- Main content -->
        <main class="products-list-main-s">
          <!-- Products Filter -->
          <div class="list-settings">
            <div class="shown-quantity" *ngIf="products">
              <p>Mostrando de 1 a {{ products.length }}</p>
            </div>
            <div class="order-by-filter">
              <label
                class="order-by-label"
                for="orderby"
                (click)="toggleSelect()"
                #label
              >
                Ordenar itens
                <img
                  src="/assets/images/arrow-down.png"
                  alt="Dropdown Icon"
                  #dropdownIcon
                />
              </label>
              <select
                class="order-by-select"
                name="orderby"
                id="orderby"
                (change)="orderList($event.target.value)"
                [ngClass]="{ visible: selectVisible }"
                #select
              >
                <option value="ordem">Ordenação padrão</option>
                <option value="a-z">Alfabética, A-Z</option>
                <option value="z-a">Alfabética, Z-A</option>
                <option value="low">Menor preço</option>
                <option value="high">Maior preço</option>
              </select>
            </div>
          </div>

          <!-- Products Grid -->
          <section class="product-grid">
            <div
              class="product-grid-card product-card"
              *ngFor="let slide of slides | orderBy : sortByOrder"
            >
              <div class="product-card-top">
                <img [src]="slide.image" alt="Card Image" />
                <div class="product-card-info">
                  <h3>{{ slide.name }}</h3>
                  <p>{{ slide.code }} {{ slide.price }}</p>
                </div>
              </div>
              <div class="product-card-body">
                <button
                  routerLink="/product-description/{{ slide.productData.id }}"
                  class="btn btn-secondary"
                >
                  <a
                    routerLink="/product-description/{{ slide.productData.id }}"
                  >
                    Comprar
                  </a>
                </button>
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  </section>

  <!-- Bottom mini banners -->
  <section>
    <div class="container">
      <div class="bottom-s">
        <div class="products-banners-s">
          <div class="banner-wrapper">
            <!-- Mini Banners -->
            <app-minibanner
              *ngFor="let category of moreCategories"
              [categoryName]="category.name"
              [categoryHighlight]="category.highlight"
              [categoryImage]="category.imageUrl"
              [categoryPrice]="category.value"
              [categoryDescription]="category.description"
              [categoryId]="category.categoryId"
              [categoryCode]="category.code"
            >
            </app-minibanner>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-social-media-section></app-social-media-section>
</div>
