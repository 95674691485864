<div class="minibanner">
  <div 
    class="minibanner-name" 
    [style.background]="'url(' + categoryImage + ')'" 
    [style.background-repeat]="'no-repeat'" 
    [style.background-position]="'center center'" 
    [style.background-size]="'cover'"
  >
    <h2>{{ categoryName }}<span>{{ categoryHighlight }}</span></h2>
  </div>
  <div class="minibanner-body">
    <div class="price-link">
      <p>A partir de {{categoryCode}}{{ categoryPrice }},00</p>
      <button class="btn btn-default" (click)="onNavigateToCategory(categoryId)">
        <a (click)="onNavigateToCategory(categoryId)">Conheça todas as oportunidades!</a>
      </button>
    </div>
    <div class="txt">
      <p>{{ categoryDescription }}</p>
    </div>
  </div>
</div>