<div class="breadcrumb">
  <ul>
    <li>
      <a routerLink="/">Início</a>
    </li>
    <li *ngIf="name">/</li>
    <li *ngIf="name">Categorias</li>
    <li *ngIf="name">/</li>
    <li *ngIf="name">
      <a [routerLink]="'/product-category-list/' + categoryId">{{ name }} {{ highlight }}</a>
    </li>
    <li *ngIf="product">/</li>
    <li *ngIf="product">
      <a href="">{{ product }}</a>
    </li>
  </ul>
</div>