<app-wrapper-loading-spinner [isLoading]="isLoading"></app-wrapper-loading-spinner>

<div class="carousel-s">
  <div class="container">
    <div class="top-s title-s">
      <img src="assets/images/airplane (1).png" alt="Avião tracejado">
      <h1>Não <span>perca:</span></h1>
    </div>
    <div class="carousel-wrapper">
      <ngx-slick-carousel class="carousel" [config]="carouselConfig">
        <div class="product-card carousel-card-item" *ngFor="let slide of slides" ngxSlickItem (click)="redirectTo(slide.link)">
          <img [src]="slide.image" alt="Card Image" />
          <div class="product-card-body">
            <h3>{{ slide.name }}</h3>
            <p>{{ slide.code }} {{ slide.price }}</p>
            <button 
              class="btn btn-secondary custom-btn" 
              routerLink="{{ slide.link }}" 
              [style.background-color]="slide.buttonColor"
              (click)="redirectTo(slide.link)"
            >
              <a href="{{slide.link}}" [style.color]="slide.fontColor">Viva essa experiência!</a>
            </button>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div> 