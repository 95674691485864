import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { BehaviorSubject, from, Observable, of, Subscriber, timer } from "rxjs";
import { map, filter, scan } from "rxjs/operators";
import "rxjs/add/operator/map";
import { HttpClient } from "@angular/common/http";

// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  public currency: string = "USD";
  public catalogMode: boolean = false;

  public compareProducts: BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer: Subscriber<{}>;
  public digital = [];
  public phisical = [];

  private baseProductsUrl =
    "https://southamerica-east1-ecommerce-f6bae.cloudfunctions.net/vessel-products";

  private allProducts: BehaviorSubject<Product[]> = new BehaviorSubject(null);

  // Initialize
  constructor(private http: HttpClient, private toastrService: ToastrService) {
    this.loadAllProducts();
  }

  private loadAllProducts() {
    this.getProductsReq().subscribe((products) => {
      let theProducts = products.filter((product) => {
        return product.active === "true" && product.status === "true";
      });
      this.allProducts.next(theProducts);
    });
  }

  // Get Products
  public getProducts(): Observable<Product[]> {
    return new Observable((observer) => {
      // Check if the BehaviorSubject is already populated
      if (this.allProducts.getValue()) {
        // If populated, return it as an Observable
        observer.next(this.allProducts.getValue());
        observer.complete();
      } else {
        // If not populated, make an HTTP request to load the products
        this.getProductsReq().subscribe((products) => {
          let theProducts = products.filter((product) => {
            return product.active === "true" && product.status === "true";
          });
          // Update the BehaviorSubject
          this.allProducts.next(theProducts);
          // Return the products
          observer.next(theProducts);
          observer.complete();
        });
      }
    });
  }

  public getProductsPhisical(): Observable<Product[]> {
    return this.getProductsPhisicalReq();
  }

  public getProductsAll(): Observable<Product[]> {
    return this.getProducts();
  }

  public getProduct(prodId: string): Observable<Product> {
    return this.getProductReq(prodId);
  }

  public getProductReq(id: string): Observable<Product> {
    const url = `${this.baseProductsUrl}/product-up/${id}?digital=true`;
    const headers = { "content-type": "application/json" };
    return this.http.get<Product>(url, { headers: headers });
  }

  public getProductsReq(): Observable<Product[]> {
    const url = `${this.baseProductsUrl}/products-up`;
    const headers = { "content-type": "application/json" };
    return this.http.get<Product[]>(url, { headers: headers });
  }

  public getProductsPhisicalReq() {
    const url = `${this.baseProductsUrl}/products-up?digital=false`;
    const headers = { "content-type": "application/json" };
    return this.http.get<Product[]>(url, { headers: headers });
  }

  // Get Products By Category
  public getProductByCategory(categoryId: string): Observable<Product[]> {
    return new Observable((observer) => {
      this.getProductByCategoryReq(categoryId).subscribe((products) => {
        let theProducts = products.filter((product) => {
          return product.active === "true" && product.status === "true";
        });
        observer.next(theProducts);
        observer.complete();
      });
    });
  }

  private getProductByCategoryReq(categoryId: string): Observable<Product[]> {
    const url = `${this.baseProductsUrl}/products-up?digital=true&category=${categoryId}`;
    const headers = { "content-type": "application/json" };
    return this.http.get<Product[]>(url, { headers: headers });
  }
  /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

  // Get Compare Products
  public getComapreProducts(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(products);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // If item is aleready added In compare
  public hasProduct(product: Product): boolean {
    const item = products.find((item) => item.id === product.id);
    return item !== undefined;
  }

  // Add to compare
  public addToCompare(product: Product): Product | boolean {
    var item: Product | boolean = false;
    if (this.hasProduct(product)) {
      item = products.filter((item) => item.id === product.id)[0];
      const index = products.indexOf(item);
    } else {
      if (products.length < 4) products.push(product);
      else this.toastrService.warning("Maximum 4 products are in compare."); // toasr services
    }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
  }

  // Removed Product
  public removeFromCompare(product: Product) {
    if (product === undefined) {
      return;
    }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
  }
}

export function getObjectInArray(
  observable: Observable<Product[]>,
  lista: any[]
): Promise<any> {
  return new Promise((resolve, reject) => {
    observable.subscribe(
      (itens) => {
        while (lista.length > 0) {
          lista.pop();
        }
        itens.forEach((it) => {
          lista.push({
            id: it.id,
          });
        });
        resolve(1);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
