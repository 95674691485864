import {
  Component,
  Inject,
  HostListener,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { LandingFixService } from "../../services/landing-fix.service";
import { DOCUMENT } from "@angular/common";
import { WINDOW } from "../../services/windows.service";
import { CartItem } from "../../classes/cart-item";
import { CartService } from "../../services/cart.service";
import { Observable, of } from "rxjs";
import { AuthenticationService } from "../../services/authentication.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { UserStateService } from "../../services/user-state.service";
import { Router } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  public shoppingCartItems: CartItem[] = [];
  isMobileMenuOpen: boolean = false;
  isLoggedIn: boolean;
  showDropdown: boolean = false;
  isDropdownVisible: boolean = false;
  userEmail: string | null = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    private fix: LandingFixService,
    private cartService: CartService,
    private authenticationService: AuthenticationService,
    private angularFireAuth: AngularFireAuth,
    private userStateService: UserStateService,
    private router: Router
  ) {}

  ngOnInit() {
    $.getScript("assets/js/menu.js");
    this.cartService
      .getItems()
      .subscribe(
        (shoppingCartItems) => (this.shoppingCartItems = shoppingCartItems)
      );
    // this.checkWindowSize();

    this.authenticationService.loginStatusSubject.subscribe((loginStatus) =>
      this.updatedStatusLogin(loginStatus)
    );
  }

  updatedStatusLogin(loginStatus: boolean) {
    this.isLoggedIn = loginStatus;

    if (loginStatus) {
      this.angularFireAuth.authState.subscribe((user) => {
        if (user) {
          this.userEmail = user.email;
        } else {
          this.userEmail = null;
        }
      });
    }
  }

  openNav() {
    this.fix.addNavFix();
  }

  closeNav() {
    this.fix.removeNavFix();
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(["home"]);
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let element = this.document.getElementById("sticky");
    let number =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (element && number >= 300) {
      element.classList.add("fixed");
    } else {
      element.classList.remove("fixed");
    }
  }

  // @HostListener('window:resize')
  // onWindowResize(){
  //   this.checkWindowSize();
  // }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    this.document.body.classList.toggle("scroll-lock", this.isMobileMenuOpen);
  }

  // checkWindowSize(){
  //   this.isMobileMenuOpen = this.window.innerWidth > 1024 ? false : this.isMobileMenuOpen;
  //   if(this.window.innerWidth > 1024){
  //     this.document.body.classList.remove('scroll-lock');
  //   }
  // }
}
