import { Component, OnInit, Input } from "@angular/core";
import { Product } from "../../../../shared/classes/product";
import { ProductsService } from "../../../../shared/services/products.service";

@Component({
  selector: "app-new-product",
  templateUrl: "./new-product.component.html",
  styleUrls: ["./new-product.component.scss"],
})
export class NewProductComponent implements OnInit {
  public products = [];
  public products3 = [];
  public digital = [];
  public aux_products: Product[] = [];

  public index = [];
  public teste = [];

  constructor(private productsService: ProductsService) {}

  ngOnInit() {
    this.productsService.getProductsAll().subscribe((product) => {
      let allProducts = [];
      product.forEach((item) => {
        if (item["status"] != "false") {
          allProducts.push(item);
        }
      });
      let aux_products3 = [];
      allProducts.sort(function (a, b) {
        var dateA = new Date(a.recent_data);
        var dateB = new Date(b.recent_data);
        if (!b.recent_data) {
          var dateB = new Date("Fri, 13 Mar 2020 12:20:14 GMT");
        }
        if (!a.recent_data) {
          var dateA = new Date("Fri, 13 Mar 2020 12:20:14 GMT");
        }
        return dateB.valueOf() - dateA.valueOf();
      });

      for (let i = 0; allProducts.length > i; i += 3) {
        aux_products3.push(allProducts.slice(i, i + 3));
      }
      this.products3 = aux_products3;
      this.index = Array.from(Array(aux_products3.length).keys());
    });
  }
}
