<div class="img-wrapper">
  <div class="lable-block">
    <span class="lable3" *ngIf="product.new">new</span>
    <span class="lable4" *ngIf="product.sale">on sale</span>
  </div>
  <div class="front">
    <a [routerLink]="['/home/left-sidebar/product', product.id]"
      ><img
        [src]="!variantImage ? product.photos[0] : variantImage"
        class="img-fluid"
        alt=""
    /></a>
  </div>
  <ul class="product-thumb-list">
    <li
      class="grid_thumb_img"
      [ngClass]="{ active: selectedItem == variant.images }"
      *ngFor="let variant of product.variants"
    >
      <a [routerLink]="" (click)="changeVariantImage(variant.images)">
        <img [src]="variant.images" />
      </a>
    </li>
  </ul>
  <div class="cart-info cart-wrap" *ngIf="!productsService?.catalogMode">
    <!-- <a
      [routerLink]=""
      [attr.data-target]="'#addtocart' + product.id"
      data-toggle="modal"
      (click)="addToCart(product)"
      data-placement="left"
      title="Adicionar ao carrinho"
    >
      <i class="ti-shopping-cart"></i>
    </a> -->
    <a
      [routerLink]=""
      (click)="addToWishlist(product)"
      title="Adicionar ao favorito"
    >
      <i class="ti-heart" aria-hidden="true"></i>
    </a>
    <a
      [routerLink]=""
      [attr.data-target]="'#quick-view' + product.id"
      data-toggle="modal"
      title="Quick View"
    >
      <i class="ti-search" aria-hidden="true"></i>
    </a>
    <a
      [routerLink]="['/home/compare']"
      (click)="addToCompare(product)"
      title="Compare"
    >
      <i class="ti-reload" aria-hidden="true"></i>
    </a>
  </div>
</div>
<div class="product-detail">
  <div>
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a [routerLink]="['/home/left-sidebar/product', product.id]"
      ><h6>{{ product.name }}</h6></a
    >
    <h4 *ngIf="!productsService?.catalogMode">
      {{ product.price | currency: product["moedaCode"] }}
      <del
        ><span class="money">{{
          product.salePrice | currency: product["moedaCode"]
        }}</span></del
      >
    </h4>
    <ul class="color-variant" *ngIf="!productsService?.catalogMode">
      <li
        [class]="variant.color"
        (click)="changeVariantImage(variant.images)"
        *ngFor="let variant of product.variants"
      ></li>
    </ul>
  </div>
</div>
