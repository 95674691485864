import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriaService } from 'src/app/shared/services/categoria.service';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  categories: any[] = [];

  constructor(
    private categoryService: CategoriaService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadCategories();
  }

  loadCategories(){
    this.categoryService.getCategorias().subscribe((categories) => {
      this.categories = categories.filter(category => category.MostrarMenu == true);
    })
  }

  navigateToCategory(categoryId: string) {
    // Navigate to the same route, but add a navigation extra to trigger a reload
    this.router.navigate(['/product-category-list', categoryId], { queryParamsHandling: 'merge', replaceUrl: true })
      .then(() => {
        // Triggering the navigation again to force a reload
        this.router.navigate(['/product-category-list', categoryId]);
      });
  }

}
