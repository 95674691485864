<div class="collection-mobile-back" (click)="mobileFilterBack()">
  <span class="filter-back">
    <i class="fa fa-angle-left" aria-hidden="true"></i> voltar
  </span>
</div>
<div class="collection-collapse-block border-0 open">
  <h3 class="collapse-block-title">Categoria</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ul class="category-list">
        <ng-container *ngFor="let item of categories">
          <li *ngIf="item.MostrarMenu">
            <a [routerLink]="'/home/left-sidebar/collection/' + item.id">{{
              item.Nome
            }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
